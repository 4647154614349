import { Link } from 'react-router-dom';

import { BiSupport } from "react-icons/bi"; // You may replace this with your own support-related icon

const SupportPlanPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md max-w-md w-full">
        <h2 className="text-3xl font-bold mb-6">Full Support Plan</h2>
        <p className="text-gray-600 mb-8">
          Enjoy a seamless experience with our Full Support Plan. Our team is dedicated to providing you with top-notch assistance whenever you need it.
        </p>
        <div className="mb-8">
          <BiSupport className="w-12 h-12 mx-auto mb-2" />
          <h3 className="text-xl font-semibold">What's Included:</h3>
          <ul className="list-disc list-inside text-gray-600">
            <li>24/7 Customer Support</li>
            <li>Personalized Assistance</li>
            {/* Add more features here */}
          </ul>
        </div>
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-2">Price: $39.99/month</h3>
          <p className="text-gray-600">
            Enjoy all the benefits of our Full Support Plan for just $39.99 per month. Cancel anytime.
          </p>
        </div>
        <Link to="#" className="block w-full bg-blue-500 text-white py-2 px-4 rounded-md text-center hover:bg-blue-600">
          Subscribe Now
        </Link>
      </div>
    </div>
  );
};

export default SupportPlanPage;