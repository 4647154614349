import parse from 'html-react-parser';
import React from "react";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import * as AI from "react-icons/ai";
import * as IO from "react-icons/io";
import * as LU from "react-icons/lu";
import * as RDom from "react-router-dom";
import * as rDom from 'react-router-dom';
import useMyContext from "../../context/useMyContext";
import { handleImageError, pictureSrcForProduct, pictureSrcUpdate } from "../../utils/imgsrc-utils";
import publicRequest from "../../utils/requestMethods";
import CustomSingleSlider from "../../components/_customcarSlider/CustomSingleSlider";
import Breadcrumbs from '../../components/breadcrumbs/BreadCrumbs';
import { custom } from '../../components/customs/custom';
import PaymentModal from "../../components/modal/HidePayment";
import SearchProduct from '../../components/searchProduct/SearchProduct';
import Productskeleton from '../../components/skeleton/Productskeleton';
import { linkClick } from '../../components/handelclicks/link-click';
import CarCarousel from '../../components/text-carousel/CarCarousel';

const SingleProduct = ({ data }) => {
  const params = RDom.useParams();
  const { make, model, year, vin } = params;


  const [swtichImage, setSwitchImage] = React.useState(0);
  const [singleProductData, setSingleProductData] = React.useState(data?.vin_data);
  const [suggestData, setSuggestData] = React.useState(data?.similar_vins);
  const { pathname } = RDom.useLocation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);



  React.useEffect(() => {
    async function getProduct() {
      try {
        const productById = await publicRequest.get(`/cars/auc/${make}/${model}/${year}/${vin}`);
        setSingleProductData(productById.data);
        setLoading(false);
      } catch (err) {
        setError(true)
        setLoading(false);
      }
    }
    getProduct()
  }, [make, model, year, vin]);




  React.useEffect(() => {
    async function getSuggestByModels() {
      try {
        const response = await publicRequest.get(`/suggest/auc/vin/${year !== "undefined" ? year : singleProductData?.car_age} ${make !== "undefined" ? make : singleProductData?.makes} ${model !== "undefined" ? model : singleProductData?.model}`);
        setSuggestData(response?.data)
      } catch (err) {
        console.log(err)
      }
    }

    getSuggestByModels()
  }, [year, make, model])


  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleCopyVin = async () => {
    if (singleProductData?.vin) {
      await navigator.clipboard.writeText(singleProductData?.vin)
        .then(() => {
          console.log('Text copied to clipboard successfully');
        })
        .catch((error) => {
          console.error('Error copying text to clipboard:', error);
        });
    }
  };

  const handleSwitchImage = (index) => {
    setSwitchImage(index);
  };

  const { carId, updateData } = useMyContext();



  React.useEffect(() => {
    if (openModal) {
      updateData(singleProductData?.id)
    }
  }, [openModal])


  const nameParts = singleProductData?.name?.split(' ');
  const itemYear = nameParts?.[0]?.toLowerCase();
  const itemMake = nameParts?.[1]?.toLowerCase();
  const itemModel = nameParts?.[2]?.toLowerCase();

  const ltdJsonList = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://vehicleread.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Vin Decoder",
        "item": "https://vehicleread.com/vin-decoder/all-makes/all-models/all-years?page=1"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": `${itemMake}`,
        "item": `https://vehicleread.com/vin-decoder/${itemMake}/all-models/all-years?page=1`
      },

      {
        "@type": "ListItem",
        "position": 4,
        "name": `${itemModel}`,
        "item": `https://vehicleread.com/vin-decoder/${itemMake}/${itemModel}/all-years?page=1`
      },

      {
        "@type": "ListItem",
        "position": 5,
        "name": `${singleProductData?.name} VIN: ${singleProductData?.vin} `,
        "item": `https://vehicleread.com/vin-decoder/${itemMake}/${itemModel}/${itemYear}/${singleProductData?.vin}`
      }
    ]
  }



  const ltdJsonShcema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "vehicleread",
    "url": "https://vehicleread.com",
    "logo": "https://vinhistory.s3.amazonaws.com/public/admin/web-3logo.png",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1845-394-5380",
      "contactType": "Customer Support",
      "availableLanguage": "English"
    },
    "sameAs": [
      "https://www.facebook.com/vehicleread",
      "https://twitter.com/vehicleread"
    ]
  }
  const currentDate = new Date();

  // Extract the date components
  const year_date = currentDate.getFullYear();
  const month_date = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
  const day_date = String(currentDate.getDate()).padStart(2, '0');
  const formattedPrice = `${/^\d+(\.\d{1,2})?$/.test(singleProductData?.final_bid_formatted) ? singleProductData?.final_bid_formatted === "0.00" ? "111" : singleProductData?.final_bid_formatted : "999"}`;
  const result = singleProductData?.odometer_to_string?.match(/^(\d+)(.*)$/);
  const odometerValue = result ? `${result[1]}` : '9999';

  // Format the date as "YYYY-MM-DD"
  const formattedDate = `${year_date}-${month_date}-${day_date}`;
  const ltdJson = {
    "@context": "https://schema.org",
    "@type": "Car",
    "url": `https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}/${vin}`,
    "name": `${singleProductData?.vin} |${singleProductData?.name?.replace(/ /g, '-')}  | vehicleread`,
    "description": `✔️ VIN: ${singleProductData?.vin} |${singleProductData?.name?.replace(/ /g, '-')}  Lot: ${singleProductData?.lot}  `,
    "vehicleIdentificationNumber": `${vin}`,
    "sku": `${vin}`,
    "mpn": `${vin}`,
    "fuelType": `${singleProductData?.fuel_type ? singleProductData?.fuel_type : "Unknown"}`,
    "knownVehicleDamages": {
      "primary": `${singleProductData?.primary_damage ? singleProductData?.primary_damage : "Unknown"}`,
      "secondary": `${singleProductData?.secondary_damage ? singleProductData?.secondary_damage : "Unknown"}`,
    },

    "mileageFromOdometer": {
      "@type": "QuantitativeValue",
      "value": odometerValue,
      "unitText": "miles",
      "unitCode": "SMI",
    },
    "brand": { "@type": "Brand", "name": `${itemMake}` },
    "vehicleModelDate": `${itemYear}`,
    "model": `${itemModel}`,
    "additionalProperty": [
      {
        "@type": "PropertyValue",
        "name": "Key",
        "value": "Yes"
      }

    ],
    "itemCondition": "https://schema.org/UsedCondition",
    "offers":
    {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": formattedPrice,
      "availability": "https://schema.org/InStock",

    },
    "image": ` ${pictureSrcUpdate(singleProductData)}`
  }

  const handleScroll = (scrollAmount) => {
    const container = document.querySelector('.scroll-container');
    if (container) {
      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const crumbs = [
    { label: 'Home', path: '/' },
    { label: `Vin Decoder`, path: `/vin-decoder` },
    { label: `${make}`, path: `/vin-decoder/${make?.toLowerCase()}/all-models/all-years?page=1` },
    { label: `${model}`, path: `/vin-decoder/${make?.toLowerCase()}/${model.toLowerCase()}/all-years?page=1` },
    { label: `${year}`, path: `/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}?page=1` },
    { label: `${vin}`, path: `/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}/${vin}` },
  ];


  const helmet = {
    title: () => {
      return (`${singleProductData?.vin ? singleProductData?.vin : vin} - ${singleProductData?.name ? singleProductData?.name : year + " " + make?.toUpperCase() + " " + model?.toUpperCase()} | vehicleread`)
    }
    ,
    metaTitle: () => {
      return `${singleProductData?.vin ? singleProductData?.vin : vin} - ${singleProductData?.name ? singleProductData?.name : year + " " + make?.toUpperCase() + " " + model?.toUpperCase()} | vehicleread`
    }
  }

  const date = new Date(singleProductData?.prebid_close_time);

  const formdDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return (
    <>
      <>
        <SearchProduct />
      </>
      <div className="bread-crumbs-list  items-start pt-10  border-b-2 border-b-[#d1d5db]  md:text-base ">
        <Breadcrumbs crumbs={crumbs} />
      </div>
      <div className="SingleProduct  p-5 flex flex-col items-center w-full overflow-x-hidden  justify-center">
        {(!singleProductData?.vin && !error) && <> <Productskeleton /> </>}
        {
          error && <div className="flex flex-col items-center justify-center gap-3 w-full h-screen">
            <span className='whitespace-nowrap'>{year} {itemMake} {model} {vin}</span>
            <span>Access to this vin code is restricted or does not exist</span>
            <rDom.Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue active:bg-blue-800">
              Back Home
            </rDom.Link>
          </div>
        }


        <Helmet>
          <title>{`${helmet.title()}`}</title>
          <meta name="title" content={`${helmet.metaTitle()}`} />
          <meta name="description" content={`VIN: ✔️ ${singleProductData?.vin} Name: ${singleProductData?.name?.replace(/ /g, '-')} Lot: ${singleProductData?.lot} ➡️ Primary: ${singleProductData?.primary_damage} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.start_code ? `Start Code : ${singleProductData?.start_code}` : ""}  ${singleProductData?.engine ? `Engine : ${singleProductData?.engine}` : ""}  ${singleProductData?.secondary_damage ? `Secondary : ${singleProductData?.secondary_damage}` : ""} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.fuel_type ? `Fuel : ${singleProductData?.fuel_type}` : ""} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.cylinders ? `Cylinder : ${singleProductData?.cylinders}` : ""}`} />
          <link rel="alternate" hreflang="en" href={`https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}/${vin}`} />
          <link rel="alternate" hreflang="x-default" href={`https://vehicleread.com/vin-decoder/${make.toLowerCase()}/${itemModel}/${year}/${vin}`} />
          <link rel="canonical" href={`https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}/${vin}`} />
          <meta name="robots" content="index, follow" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={`https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}/${vin}`} />
          <meta property="og:title" content={`${helmet.metaTitle()} `} />
          <meta property="og:description" content={`VIN: ✔️ ${singleProductData?.vin} Name: ${singleProductData?.name?.replace(/ /g, '-')} Lot: ${singleProductData?.lot} ➡️ Primary: ${singleProductData?.primary_damage} odometer: ${singleProductData?.odometer_to_string}  ${singleProductData?.start_code ? `Start Code : ${singleProductData?.start_code}` : ""}  ${singleProductData?.engine ? `Engine : ${singleProductData?.engine}` : ""}  ${singleProductData?.secondary_damage ? `Secondary : ${singleProductData?.secondary_damage}` : ""} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.fuel_type ? `Fuel : ${singleProductData?.fuel_type}` : ""} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.cylinders ? `Cylinder : ${singleProductData?.cylinders}` : ""}`} />
          <meta property="og:image" content={`${pictureSrcUpdate(singleProductData)}`} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={`https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}/${vin}`} />
          <meta property="twitter:title" content={`${helmet.metaTitle()}`} />
          <meta property="twitter:description" content={`VIN: ✔️ ${singleProductData?.vin} Name: ${singleProductData?.name?.replace(/ /g, '-')} Lot: ${singleProductData?.lot} ➡️ Primary: ${singleProductData?.primary_damage} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.start_code ? `Start Code : ${singleProductData?.start_code}` : ""}  ${singleProductData?.engine ? `Engine : ${singleProductData?.engine}` : ""}  ${singleProductData?.secondary_damage ? `Secondary : ${singleProductData?.secondary_damage}` : ""} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.fuel_type ? `Fuel : ${singleProductData?.fuel_type}` : ""} odometer: ${singleProductData?.odometer_to_string} ${singleProductData?.cylinders ? `Cylinder : ${singleProductData?.cylinders}` : ""}`} />
          <meta property="twitter:image" content={`${pictureSrcUpdate(singleProductData)}`} />

          <script type="application/ld+json">
            {JSON.stringify(ltdJsonList)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(ltdJsonShcema)}
          </script>
          <script type="application/ld+json">
            {JSON.stringify(ltdJson)}
          </script>
        </Helmet>

        {singleProductData?.vin && <div className="p-10 ">
          <div className="bg-white p-6 rounded-lg shadow-lg   mx-auto">
            <h1 className="text-3xl  mb-6">{singleProductData?.vin} - Free VIN Decoder</h1>
            <p className="text-gray-600 text-lg  mb-6">
              Get full information with the free VIN decoder for {singleProductData?.vin}.
            </p>
            <p className="text-gray-600 text-lg  mb-6">
              This vehicle, a {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model},
              offers essential insights, including its current condition,
              odometer reading, damage report, and auction details.
            </p>
          </div>

          <header className="flex flex-col sm:flex-row sm:justify-between gap-2 items-center  mx-auto p-6   bg-opacity-80 shadow-md rounded-lg ">
            <span className="sm:text-[17px]  text-[15px] p-3">{singleProductData?.vin}</span>
            <span className="sm:text-[17px] text-[15px] p-3">{singleProductData?.name}</span>
          </header>
          <div className="flex flex-col md:flex-row  pt-4">
            <div className="sm:pt-4 text-[10px] m-auto   p-4 md:rounded-l-xl shadow-lg w-full  md:w-[60%] sm:text-sm">
              <span className="p-2 text-xl  border-b border-[#fff]">Car Details</span>
              <div className="pt-2 p-3 m-1 gap-3 flex justify-between  border-b  border-[#0d1d25]">
                <span>Vin</span>
                <p className="flex items-center ">
                  {singleProductData?.vin} <AI.AiFillCopy onClick={handleCopyVin} cursor={"pointer"} />
                </p>
              </div>
              <div className="pt-2 p-3 m-1  flex justify-between  ">
                <span>Odometer</span>
                <span>{singleProductData?.odometer ? singleProductData?.odometer + "(mi)" : singleProductData?.odometer_to_string}</span>
              </div>
              {custom.span("BodyStyle", singleProductData?.body_style)}
              {custom.span("Primary Damage", singleProductData?.primary_damage)}
              {custom.span("Secondary Damage", singleProductData?.secondary_damage)}
              {custom.span("Key", singleProductData?.key_type?.toUpperCase())}
              {custom.span("Engine", singleProductData?.engine)}
              {custom.span("Fuel", singleProductData?.fuel_type)}
              {custom.span("Start Code", singleProductData?.start_code)}
              {(singleProductData?.makes || itemMake) && custom.span("Make", singleProductData?.makes || make)}
              {(singleProductData?.model || model) && custom.span("Model", singleProductData?.model || model)}
              {(singleProductData?.car_age || year) && custom.span("Year", year)}
              {custom.span("Drive", singleProductData?.drive_type)}
              {custom.span("Transmission", singleProductData?.transmission)}
              {custom.span("Cylinder", singleProductData?.cylinders)}

            </div>
            {isModalOpen && <PaymentModal carId={singleProductData?.id} closeModal={closeModal} />}
            <div className="bg-[#fffff]  flex flex-col gap-y-5  rounded-b-md text-[10px] sm:text-[16px]  w-[100%] h-[200px] md:w-[40%] md:h-[200px]  max-w-4xl  p-6   bg-opacity-80 shadow-md rounded-lg py-2 ">
              <span className="  text-xl">
                Additional Inforamtion
              </span>
              {singleProductData?.prebid_close_time &&
                <div className=" flex justify-between">
                  <span className="flex gap-3 align-items">  <LU.LuCalendarCheck size="20" /> Auction Date </span>
                  <span>{formdDate}</span>
                </div>
              }
              <div className="flex    justify-between">
                <span className="flex gap-3 items-center"><IO.IoIosPricetag size="20" /> Lot </span>
                <span className=" flex items-center gap-x-3">
                  {singleProductData?.lot} <AI.AiFillCopy cursor={"pointer"} />
                </span>
              </div>
              <div className="flex items-center gap-x-10">
                <span>Final Price</span>
                <span>
                  $
                  {
                    singleProductData?.final_bid_formatted === "0" || !singleProductData?.final_bid_formatted || singleProductData?.final_bid_formatted === "---"
                      ? "0.00"
                      : singleProductData?.final_bid_formatted
                  }
                </span>
              </div>
              <div onClick={openModal} className="flex  items-center gap-3 bg-red-800 p-2 w-[70%] rounded-md justify-center m-auto cursor-pointer">
                <AI.AiOutlineDelete size="20" color="white" />
                <span className="text-white text-[15px]">Hide Vehicle Information</span>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg   mx-auto">
            <h2 className="text-3xl  mb-6 ">{singleProductData?.vin} - Lot {singleProductData?.lot} - {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} Auction Info</h2>
            <p className="text-gray-700 mb-4">
              This vehicle was featured in auction lot {singleProductData?.lot},
              where it garnered significant interest due to its condition and specifications.
            </p>
            <p className="text-gray-700 mb-4">
              The final auction price was {singleProductData?.final_bid_formatted},
              reflecting the market's valuation based on the vehicle's current state and potential for repair.
            </p>
          </div>
          <div className="flex flex-col container gap-5 pt-5  mx-auto p-6   bg-opacity-80 shadow-md rounded-lg  ">
            <CustomSingleSlider
              item={singleProductData}
              currentImage={swtichImage}
            />
            <div className="relative container flex items-center">
              {/* Left Arrow */}
              <button
                className="absolute left-0 z-10 p-2  text-white rounded-full hover:bg-gray-700"
                onClick={() => handleScroll(-150)} // Adjust scroll amount as needed
              >
                <AI.AiOutlineArrowLeft />
              </button>



              {/* Scrollable Container */}
              <div className="scroll-container flex overflow-auto scrollbar-hide">
                {singleProductData?.imgUrls?.map((item, index) => (
                  <img
                    key={index}
                    onClick={() => handleSwitchImage(index)}
                    className="w-[150px] h-[70px] sm:w-[170px] sm:h-[110px] border rounded-[8px] transition-all duration-300 hover:border-gray-500"
                    width="150px"
                    height="70px"
                    alt={`VIN ${singleProductData?.vin} ${singleProductData?.name?.replace(`${singleProductData?.vin}`, '')} no.${index + 1}`}
                    src={pictureSrcForProduct(singleProductData, item)}
                    onError={handleImageError}
                  />
                ))}
              </div>

              {/* Right Arrow */}
              <button
                className="absolute right-0 z-10 p-2  text-white rounded-full hover:bg-gray-700"
                onClick={() => handleScroll(150)} // Adjust scroll amount as needed
              >
                <AI.AiOutlineArrowRight />
              </button>
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg   mx-auto">
            <h2 className="text-3xl  mb-6">{singleProductData?.vin} - {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} Vehicle Details</h2>
            <p className="text-gray-700 mb-4">
              This {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} is equipped with a {singleProductData?.engine ?? "Unknown"} gas engine,
              providing reliable performance with a {singleProductData?.drive_type ?? "Unknown"} drive system.
            </p>
            <p className='text-gray-700 mb-4'>
              The current odometer reading stands at {singleProductData?.odometer_to_string ?? "Unknown"} miles, giving you an accurate idea of the vehicle's mileage.
            </p>
            <p className='text-gray-700 mb-4'>
              It has suffered {singleProductData?.primary_damage ?? "Unknown"} damage,  {singleProductData?.secondary_damage ? `and also experienced secondary damage in the form of ${singleProductData?.secondary_damage}` : "with no secondary damage reported."}.
            </p>
            <p className="text-gray-700 mb-4">
              Despite the damage, the vehicle {singleProductData?.start_code == "run and drive" ? ` is marked as "run and drive," meaning it remains operational and ready for use.` : "is not marked as operational, indicating it may require repairs before being driven."} </p>
            <p className="text-gray-700 mb-4">The key status for this car is {singleProductData?.key_type ?? "Unknown"}, adding to the overall value and convenience of the vehicle</p>
          </div>
        </div>
        }
        {singleProductData?.text_generation && <div className="  md:flex flex-col max-w-4xl mx-auto p-6   bg-opacity-80 shadow-md rounded-lg  justify-center items-center gap-2 " >
          <div>{parse(singleProductData?.text_generation)}</div>
        </div>}


        <div className="md:p-10 mx-auto p-6  bg-white bg-opacity-80 shadow-lg rounded-lg">
          <p className="w-fit border-b-2 border-green-700 text-lg font-semibold mb-6">
            See Similar Cars Like {singleProductData?.name || `${year} ${make} ${model}`}
          </p>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {suggestData?.length === 0 && <p className="text-gray-500">No Similar Cars Found.</p>}
            {suggestData?.map((item) => {
              const nameParts = item?.name?.split(' ');
              const itemYear = nameParts?.[0]?.toLowerCase();
              const itemMake = nameParts?.[1]?.toLowerCase();
              const itemModel = nameParts?.[2]?.toLowerCase();

              return (
                <div key={item.id} className="bg-gray-100 p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                  <Link
                    to={`/vin-decoder/${itemMake}/${itemModel}/${itemYear}/${item?.vin}`}
                    onClick={(e) => {
                      e.preventDefault();
                      linkClick(`/vin-decoder/${itemMake}/${itemModel}/${itemYear}/${item?.vin}`);
                    }}
                    className="text-blue-600 text-sm truncate block hover:underline"
                  >
                    {item.vin}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <CarCarousel name={singleProductData?.makes} />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6 py-2">
          {/* Left side - VIN Decoder Results */}
          <div className="bg-white p-6 rounded-md shadow-lg">
            <h3 className="text-xl font-bold text-gray-900 mb-4">VIN Decoder Results for {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} ({singleProductData?.vin})</h3>
            <p className="text-gray-700 mb-4">
              The VIN decoder results for this {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} provide a complete overview of the vehicle's specifications.
              With {singleProductData?.odometer_to_string} miles recorded, and {singleProductData?.primary_damage} listed as the main damage, the vehicle's history is clear.
            </p>
            <p className="text-gray-700 mb-4">
              For buyers or enthusiasts, this  {singleProductData?.makes} {singleProductData?.model} represents an opportunity to acquire a car with known damage and a documented history, making it easier to estimate repair costs and resale value.
            </p>
          </div>

          {/* Right side - Auction and Lot Information */}
          <div className="bg-white p-6 rounded-md shadow-lg">
            <h3 className="text-xl font-bold text-gray-900 mb-4">{singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} - VIN {singleProductData?.vin} - Auction and Lot Information</h3>
            <p className="text-gray-700 mb-4">
              At auction, this {singleProductData?.car_age} {singleProductData?.makes} {singleProductData?.model} was listed under lot number {singleProductData?.lot} and attracted attention due to its {singleProductData?.primary_damage} . The vehicle sold for ${singleProductData?.final_bid_formatted}, reflecting the condition, mileage, and repairs needed.
            </p>
            <p className="text-gray-700 mb-4">
              {
                singleProductData?.secondary_damage ?
                  `Secondary damage is also reported as ${singleProductData?.secondary_damage}, which may have influenced the final price.`
                  : "No secondary damage was reported, keeping the price reflective of the primary damage only."
              }
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default SingleProduct;

