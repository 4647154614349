import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './layout/MainLayout';
import Business from './pages/about/Business';
import Contact from './pages/contact/Contact';
import Faq from "./pages/faq/Faq";
import Home from './pages/home/Home';
import NotFoundPage from './pages/notfound/NotFoundPage';
import SingleProduct from "./pages/product/singleProduct";
import ProductsPage from "./pages/productlist/ProductsPage";
import SupportPlanPage from './pages/support/SupportPlan';
import Privacy from './pages/terms/Privacy';
import Terms from './pages/terms/Terms';
const App = ({ initialData }) => {

  return (
    <>
   
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home initialData={initialData} />} />

            {/* new router */}
            <Route path="/vin-decoder" element={<ProductsPage initdata={initialData} />} />
            <Route path='/vin-decoder/:make/:model/:year' element={<ProductsPage initdata={initialData} />} />
            <Route path='/vin-decoder/:make/:model/:year/:vin' element={<SingleProduct data={initialData} />} />

            <Route path='/vin-decoder/all-makes/all-models/all-years' element={<Navigate to={`/vin-decoder`} replace />} />

            <Route path='/contact' element={<Contact />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/about' element={<Business />} />
            <Route path='/services' element={<SupportPlanPage />} />
            <Route path='/FAQ' element={<Faq />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
    </>
  );
}

export default App;
