
import { useEffect, useState } from 'react';
import { FcInTransit } from 'react-icons/fc';
import { RiSearchLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import requestUtils from '../..//utils/requestUtils';
import { handleGenYear } from '../../utils/gen-year';
import { makeModelRequest } from '../../utils/requestMethods';

const SearchProduct = () => {
    const inputsData = {
        make: "all-makes",
        model: "all-models",
        generation: "all-years",

    };
    const navigate = useNavigate();
    const [searchCheck, setSearchCheck] = useState("");
    const [searchCheckResult, setSearchCheckResult] = useState("");
    const [selectValue, setSelectValue] = useState(inputsData);

    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);



    useEffect(() => {
        async function getMakes() {
            try {
                const response = await makeModelRequest.get('/make-models')
                setMakes(response?.data)
            } catch (err) {
                console.log(err)
            }
        }
        getMakes();
    }, []);

    useEffect(() => {
        async function getModels() {
            try {
                const response = await makeModelRequest.get(`/make-models?make=${selectValue?.make}`);
                setModels(response.data);
            } catch (err) {
                console.log(err)
            }
        }
        if (selectValue?.make) {
            getModels();
        }
    }, [selectValue?.make])



    useEffect(() => {

        if (searchCheckResult?.vin) {
            const nameParts = searchCheckResult?.name?.split(' ');
            const Year = nameParts[0];
            const Make = nameParts[1];
            const Model = nameParts[2];
            navigate(`/vin-decoder/${Make?.toLowerCase()}/${Model?.toLowerCase()}/${Year}/${searchCheckResult?.vin}`);
            window.location.reload();
        }
    }, [searchCheckResult]);



    const selectInput = (e) => {
        setSelectValue(val => {
            return { ...val, [e.target.name]: e.target.value }
        })
    }




    const handleCheckVinByVin = async () => {
        if (searchCheck) {
            try {
                await requestUtils._getProductByVin(searchCheck).then((data) =>
                    setSearchCheckResult(data)
                );
            } catch (err) {

            }
        }
    };


    const handleRouterFilteredProducts = () => {
        if (selectValue.make == "all-makes" && selectValue.model == "all-models" && selectValue.generation == "all-years") {
            navigate("/vin-decoder/all-makes/all-models/all-years");
            window.location.reload();
        } else if (selectValue.make && selectValue.model == "all-models" && selectValue.generation == "all-years") {
            navigate(`/vin-decoder/${selectValue.make?.toLowerCase()}/all-models/all-years`);
            window.location.reload();
        } else if (selectValue.make && selectValue.model && selectValue.generation == "all-years") {
            navigate(`/vin-decoder/${selectValue.make?.toLowerCase()}/${selectValue.model?.toLowerCase()}/all-years`);
            window.location.reload();
        } else if (selectValue.make && selectValue.model && selectValue.generation) {
            navigate(`/vin-decoder/${selectValue.make?.toLowerCase()}/${selectValue.model?.toLowerCase()}/${selectValue.generation}`);
            window.location.reload();
        }
    }

    return (
        <div className='search-product-filter bg-green-300 flex flex-col gap-2 md:flex-row pt-[5em] md:pt-3 md:p-3     w-full    '>

            <span className='text-white text-[14px] w-[10em]  md:hidden'>
                <FcInTransit size="40" className=" w-[30%] border-b  text-green-500 border-green-900 mr-2" />
            </span>
            <div className='grid grid-cols-3 gap-y-2 md:flex md:gap-3 md:justify-center md:items-center space-x-2 md:w-2/3 border-b-2 border-green-800 p-2 md:p-0 md:border-b-0 '>
                <label className="form-cols ">
                    <select
                        name="make"
                        id="make"
                        className="p-2 w-full text-white border-b bg-gray-900 border-green-700 focus:outline-none focus:ring focus:border-gray-900"
                        onChange={(e) => selectInput(e)}
                    >
                        <option value="all-makes">All makes</option>
                        {
                            makes?.map((value, index) => (
                                <option key={value?.id} value={value?.make}>{value?.make}</option>
                            ))
                        }
                    </select>
                </label>
                <label className="form-cols ">
                    <select
                        name="model"
                        id="model"
                        className="p-2 w-full text-white border-b bg-gray-900 border-green-700 focus:outline-none focus:ring focus:border-blue-300"
                        disabled={makes.length === 0 ? true : false}
                        onChange={(e) => selectInput(e)}
                    >
                        <option disabled={makes.length === 0 ? true : false} value="all-models">All models</option>
                        {
                            models?.map((value, index) => (
                                <option key={value?.id} value={value?.model}>{value?.model}</option>
                            ))
                        }
                    </select>
                </label>
                <label className="form-cols mx-auto  md:mx-0">
                    <select
                        name="generation"
                        id="generation"
                        className="p-2 text-white w-full bg-gray-900 border-b border-green-700 focus:outline-none focus:ring focus:border-blue-300"
                        onChange={(e) => selectInput(e)}
                    >
                        <option disabled={models.length === 0 ? true : false} value="all-years">All generations</option>
                        { models.length === 0 ? null :   handleGenYear(2013, 2025)}
                    </select>
                </label>
                <button
                    id="show-btn"
                    className="rounded-sm  w-[170px] md:rounded-md  md:w-[170px]   py-2 whitespace-nowrap bg-green-800 md:py-1.5 text-white"
                    onClick={handleRouterFilteredProducts}
                >
                    Search Vehicle
                </button>
            </div>

            <div className="relative  flex md:justify-center gap-2 md:w-[34%]">
                <input
                    name="vinsearch"
                    label="Vin Search"
                    onChange={(e) => setSearchCheck(e.target.value)}
                    className="w-full p-2 rounded-md bg-[#0000006f] border-b border-gray-500 focus:outline-none text-white pl-10"
                    placeholder="Enter 17 digit VIN number, Lot"
                />
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <RiSearchLine className="text-gray-500" />
                </div>

                <button
                    className="whitespace-nowrap absolute w-[35%]  md:w-[25%] text-[16px] h-full right-0  rounded-md text-white bg-green-600"
                    variant='text'
                    onClick={handleCheckVinByVin}
                >
                    Check VIN
                </button>
            </div>

        </div>
    )
}

export default SearchProduct
