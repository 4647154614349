import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { useEffect, useState } from 'react';
import publicRequest from '../../utils/requestMethods';
import { handleImageError, pictureSrcUpdate } from '../../utils/imgsrc-utils';
import { linkClick } from '../handelclicks/link-click';



const CarCarousel = ({ name }) => {
    const [carData, setCarData] = useState([]);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 4 } },
            { breakpoint: 768, settings: { slidesToShow: 2 } }
        ]
    };

    useEffect(() => {
        async function getCarsVin() {
            try {

                const data = await publicRequest.get(`/suggest/auc/vin/card/${name}`)
                setCarData(data.data)
            }
            catch {
                setCarData([])
            } // Fetch or process car VINs if needed
        }
        getCarsVin()
    }, [name]);

    return (
        <div className="container w-full mt-8 max-w-7xl mx-auto p-6 bg-white bg-opacity-80 shadow-md rounded-lg">
            <span className="flex items-center gap-2 text-[14px] md:text-[16px] font-bold mb-2">
                <Link to={`/vin-decoder/${name?.toLowerCase()}/all-models/all-years?page=1`}
                    onClick={(e) => {
                        e.preventDefault();
                        linkClick(`/vin-decoder/${name?.toLowerCase()}/all-models/all-years?page=1`);
                    }} className='text-blue-500'> {name?.toUpperCase()} Recently  Models</Link>
            </span>
            <Slider {...settings}>
                {carData?.map((car) => {
                    const nameParts = car?.name?.split(' ');
                    const itemYear = nameParts[0];
                    const itemMake = nameParts[1];
                    const itemModel = nameParts[2];


                    return (

                        <div key={car.id} className="p-4">
                            <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                                <img src={pictureSrcUpdate(car)} alt={car?.name} onError={handleImageError} className="w-full h-40 object-cover rounded-lg mb-4" />
                                <p className="text-sm font-semibold whitespace-nowrap text-ellipsis overflow-hidden">{car?.name}</p>
                                <p className="text-sm text-gray-600 whitespace-nowrap text-ellipsis overflow-hidden">VIN: ✔️ {car?.vin}</p>
                                <p className="text-sm text-gray-600 whitespace-nowrap text-ellipsis overflow-hidden">Date: {car?.car_age}</p>
                                <Link
                                    to={`/vin-decoder/${itemMake?.toLowerCase()}/${itemModel?.toLowerCase()}/${itemYear}/${car?.vin}`}
                                    className="text-blue-500 hover:underline mt-2 inline-block"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        linkClick(`/vin-decoder/${itemMake?.toLowerCase()}/${itemModel?.toLowerCase()}/${itemYear}/${car?.vin}`);
                                    }}
                                >
                                    Show more
                                </Link>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    );
};

export default CarCarousel;