import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { linkClick } from '../handelclicks/link-click';
import publicRequest, { makeModelRequest } from '../../utils/requestMethods';

const VinEnum = () => {
    const params = useParams();
    const { make, model, year } = params;
    const [vins, setVins] = useState();
    const [showAll, setShowAll] = useState(false);


    useEffect(() => {
        async function getSuggestByModels() {
            try {
                const response = await publicRequest.get(`/suggest/auc/vin/${year} ${make} ${model}`);
                setVins(response?.data)
            } catch (err) {
                console.log(err)
            }
        }
        if ((year || make || model) !== "undefined") {
            getSuggestByModels()
        }
    }, [year, make, model])





    const handleToggle = () => {
        setShowAll(!showAll);
    };


    return (
        <div className="container flex flex-col mx-auto p-4">
            <span className="text-xl font-bold text-center mb-6">Available Vins for {make} {model} {year}</span>
            <ul className="grid grid-cols-1 gap-4">
                {vins?.slice(0, showAll ? vins?.length : 3)?.map((car, index) => (
                    <li key={index} className="bg-white shadow-lg rounded-lg p-4 transition transform hover:scale-105">
                        <Link to={`/vin-decoder/${car?.makes}/${car?.model}/${car?.car_age}/${car?.vin}`} onClick={() => linkClick(`/vin-decoder/${car?.makes}/${car?.model}/${car?.car_age}/${car?.vin}`)}>
                            <span className="text-xl font-semibold mb-2">{car?.name} {car?.vin}</span>
                        </Link>
                    </li>
                ))}
            </ul>
            {vins?.length > 3 && (
                <div className="flex justify-center mt-4">
                    <button
                        onClick={handleToggle}
                        className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out transform hover:scale-110"
                    >
                        {showAll ? 'Show Less ▲' : 'Show More ▼'}
                    </button>
                </div>
            )}
        </div>
    )
}

export default VinEnum;
