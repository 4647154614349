import React from 'react'
import { useLocation } from 'react-router-dom'
import Toyota from './Toyota-Decoder';
import BmwDecoder from './Bmw-Decoder';

const VinDecoderTextHeader = () => {
  const { pathname } = useLocation();
  return (
    <>
      {pathname === '/vin-decoder/toyota/all-models/all-years' ? Toyota.Toyota_header() : null}
      {pathname === '/vin-decoder/bmw/all-models/all-years' ? BmwDecoder.Bmw_header() : null}
      </>
  )
}

export default VinDecoderTextHeader
