
export const pictureSrcUpdate =(items) => {  
    if(!items?.folder_name && !items?.imgUrls?.[0]?.startsWith("public/")){
      return `${items?.imgUrls?.[0]}`
    }else{
     return `${items?.imgUrls?.[0]?.startsWith("public/") ? "https://vinhistory.s3.us-west-2.amazonaws.com" : "https://vinhistory.s3.us-west-2.amazonaws.com" + "/"}${items?.folder_name ? `${items?.imgUrls?.[0]?.startsWith("public/")?"":items?.folder_name}`: ""}/${items?.imgUrls[0]}`
    }
  }


  export const pictureSrcForProduct = (singleProductData,item) => {   
    if(!singleProductData?.folder_name &&  !item.startsWith("public/")){
       return `${item}`
    }else{
       return  `${singleProductData?.folder_name ? "https://vinhistory.s3.us-west-2.amazonaws.com" + `${item?.startsWith("public/") ? "" : `/${singleProductData?.folder_name}`}/` : `https://vinhistory.s3.us-west-2.amazonaws.com/`}${item}`
    }
  }


  export const handleImageError = (e) => {
    e.target.src = `https://vinhistory.s3.us-west-2.amazonaws.com/public/admin/no-photo.jpg`;
  };