import { ThemeProvider } from '@material-tailwind/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import MyContextProvider from './context/Contextprovider';
import "./main.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { HelmetProvider } from 'react-helmet-async';

ReactDOM.hydrate(
  <BrowserRouter>
  <HelmetProvider>
      <ThemeProvider>
        <MyContextProvider>
          <App />
        </MyContextProvider>
      </ThemeProvider>
  </HelmetProvider>
    </BrowserRouter>
  , document.querySelector('#root'))


