import React from 'react'

export  const custom = {
  span : (label, text) => {
    return (
        <div className="pt-2 p-3 m-1  flex justify-between   ">
            <span>{label}</span>
            <span>{text ? text : "Unknown"}</span>
        </div>
    )
}
}


