import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { linkClick } from '../handelclicks/link-click';

const Breadcrumbs = ({ crumbs }) => {

  return (
    <nav aria-label="breadcrumb" className="bread-crumbs-list w-full p-2 pt-4 sm:pt-0   flex items-startt text-[9px]  md:text-sm text-gray-500">
      <ol className="flex items-center">
        {crumbs.map((crumb, index) => {
          const isLast = index === crumbs.length - 1;
          return (
            <Fragment key={index}>
              {isLast ? (
                <li className="flex items-center" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                  <span itemProp="name" className="text-gray-800">{crumb.label}</span>
                  <meta itemProp="position" content={index} />
                </li>
              ) : (
                <>
                  <Link to={crumb.path}
                    itemProp="itemListElement"
                    itemScope
                    itemType="http://schema.org/ListItem"
                    className="hover:underline"
                    onClick={(e) => {
                      e.preventDefault(); 
                      linkClick(crumb.path);
                    }}>{crumb.label}</Link>
                  <meta itemProp="position" content={index} />
                  <span className="mx-2">{'>'}</span>
                </>
              )}
            </Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;