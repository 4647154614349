export const helmetTitle = (make, model, year) => {
    if (make !== "all-makes" && model === "all-models" && year === "all-years") {
        return `Vin Decoder ${make} | Vehicleread`;
    } else if (make !== "all-makes" && model !== "all-models" && year === "all-years") {
        return `Vin Decoder ${make} ${model} | Vehicleread`;
    } else if (make !== "all-makes" && model !== "all-models" && year !== "all-years") {
        return `Vin Decoder ${make} ${model} ${year} | Vehicleread`;
    } else {
        return `Vin Decoder - Auction cars history`;
    }
};

export const helmetDescription = (make, model, year) => {
    if (make !== "all-makes" && model === "all-models" && year === "all-years") {
        return `${make} Vin Decoder - Lookup ${make} VIN numbers for free. Check vehicle history, get detailed car specifications, and verify the authenticity of your ${make}. Start your free VIN lookup today!`;
    } else if (make !== "all-makes" && model !== "all-models" && year === "all-years") {
        return `${make} ${model} Vin Decoder - Lookup ${make} ${model} VIN numbers for FREE. Simply enter VIN to check vehicle history, see car details, crash reports, and much more...`;
    } else if (make !== "all-makes" && model !== "all-models" && year !== "all-years") {
        return `${make} ${model} ${year} Vin Decoder - Lookup ${make} ${model} ${year} VIN numbers for FREE. Simply enter the VIN to check vehicle history, car specifications, crash reports, and much more. Start your free VIN lookup now!`;
    } else {
        return `Vin Decoder - Lookup any car's VIN numbers for FREE. Simply enter the VIN to check vehicle history, car specifications, crash reports, and much more.`;
    }
};

export const helmetURL = (page,make, model, year) => {
    if (make !== "all-makes" && model === "all-models" && year === "all-years") {
        return `https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/all-models/all-years?page=${page}`;
    } else if (make !== "all-makes" && model !== "all-models" && year === "all-years") {
        return `https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/all-years?page=${page}`;
    } else if (make !== "all-makes" && model !== "all-models" && year !== "all-years") {
        return `https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}?page=${page}`;
    } else if(!make && !model && !year) {
        return `https://vehicleread.com/vin-decoder?page=${page}`;
    }
};




export const helmetNextURL = (next,make, model, year) => {
    if (make !== "all-makes" && model === "all-models" && year === "all-years") {
        return `https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/all-models/all-years?page=${next}`;
    } else if (make !== "all-makes" && model !== "all-models" && year === "all-years") {
        return `https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/all-years?page=${next}`;
    } else if (make !== "all-makes" && model !== "all-models" && year !== "all-years") {
        return `https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}?page=${next}`;
    } else if(!make && !model && !year) {
        return `https://vehicleread.com/vin-decoder?page=${next}`;
    }
};


export const helmetPrevtURL = (prev,make, model, year) => {
    if (make !== "all-makes" && model === "all-models" && year === "all-years") {
        return `https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/all-models/all-years?page=${prev}`;
    } else if (make !== "all-makes" && model !== "all-models" && year === "all-years") {
        return `https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/all-years?page=${prev}`;
    } else if (make !== "all-makes" && model !== "all-models" && year !== "all-years") {
        return `https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}?page=${prev}`;
    } else if(!make && !model && !year) {
        return `https://vehicleread.com/vin-decoder?page=${prev}`;
    }
};