import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { FcAlphabeticalSortingAz, FcNeutralTrading, FcSettings, FcTimeline, FcViewDetails } from "react-icons/fc";
import { linkClick } from '../handelclicks/link-click';
import { FcDoughnutChart } from "react-icons/fc";
export const makeModelsData = [
    {
        id: 1,
        name: "BMW",
        model: "X5",
        year: "2015"

    },
    {
        id: 2,
        name: "Audi",
        model: "Q5",
        year: "2016"
    },
    {
        id: 3,
        name: "Mercedes-Benz",
        model: "C-Class",
        year: "2015"
    },
    {
        id: 4,
        name: "Toyota",
        model: "Camry",
        year: "2020"
    },
    {
        id: 5,
        name: "Jeep",
        model: "Wrangler",
        year: 2020
    },
    {
        id: 6,
        name: "Nissan",
        model: "Altima",
        year: "2020"
    },
    {
        id: 7,
        name: "Volkswagen",
        model: "Passat",
        year: "2019"
    },
    {
        id: 8,
        name: "Ford",
        model: "Mustang",
        year: "2020"

    },
    {
        id: 9,
        name: "Hyundai",
        model: "Tucson",
        year: "2019"
    },
    {
        id: 10,
        name: "Lexus",
        model: "RX",
        year: "2020"
    }
]

const HomeCarousel = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 2 } },
            { breakpoint: 768, settings: { slidesToShow: 1 } }
        ]
    };

    return (
        <div className="container w-full max-w-7xl mx-auto p-6 md:p-12 bg-white shadow-lg rounded-lg">
            <div>
                <span className="flex items-center gap-2 text-xl md:text-2xl font-bold mb-6">
                    <FcAlphabeticalSortingAz size={24} />
                    Vehicle Types
                </span>
                <Slider {...settings}>
                    {['Popular Makes', 'Popular Models', 'Popular Years'].map((title, index) => (
                        <div key={index} className="p-4 md:p-8 bg-gray-100 rounded-lg shadow-inner">
                            <div className="flex items-center gap-3 mb-4">
                                {index === 0 && <FcDoughnutChart size={24} />}
                                {index === 1 && <FcNeutralTrading size={24} />}
                                {index === 2 && <FcTimeline size={24} />}
                                <span className="text-lg md:text-xl font-semibold">{title}</span>
                            </div>
                            <ul className="space-y-2">
                                {makeModelsData.map((item) => (
                                    <li key={item.id}>
                                        <Link
                                            to={`/vin-decoder/${item.name.toLowerCase()}/${index === 1 && item.model || index === 2 && item.year ? item.model.toLowerCase() : 'all-models'}/${index === 2 && item.year ? item.year : 'all-years'}?page=1`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                linkClick(`/vin-decoder/${item.name.toLowerCase()}/${index === 1 && item.model || index === 2 && item.year ? item.model.toLowerCase() : 'all-models'}/${index === 2 && item.year ? item.year : 'all-years'}?page=1`);
                                            }}
                                            className="text-blue-500 hover:underline"
                                        >
                                            {item.name} {(index === 1 || index === 2) && item.model} {index === 2 && item.year}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default HomeCarousel;