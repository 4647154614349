const Contact = () => {
  const PA = process.env.REACT_APP_ASSETS_ADMIN;

  return (
    <div className="overflow-hidden">
      <div className=" h-[500px]   flex items-center justify-center overflow-hidden relative">
        <div
          className={
            "absolute left-[5%] top-[45%] md:left-[40%] md:top-[30%]  w-[350px] h-[400px] md:w-[400px] md:h-[400px] "
          }
        >
          <div className="contactInfo-right  flex flex-col w-[80%] gap-4  ">
            <h1 className="border-b-2 border-gray-600 p-3">
              Contact Informations
            </h1>

            <div className="flex items-center gap-2">
              <img
                className="w-[50px] h-[50px] "
                src={"https://vinhistory.s3.us-west-2.amazonaws.com/public/admin/auccontactmail.png"}
                alt={"aucaboutcars"}
                width=""
                height=""
              />

              <span>auchistory@gmail.com</span>
            </div>
            <div className="flex gap-2 whitespace-nowrap w-full items-center gap:5">
              <img
                style={{ width: "50px", height: "50px", borderRadius: 50 }}
                src={"https://vinhistory.s3.us-west-2.amazonaws.com/public/admin/web3location.gif"}
                alt={"auccontactadress"}
                width=""
                height=""
              />

              <span>222 Pitkin St STE 101, East Hartford, CT 06108, USA</span>
            </div>
            <div className="flex gap-2 whitespace-nowrap items-center gap:5">
              <img
                style={{ width: "50px", height: "50px" }}
                src={"https://vinhistory.s3.us-west-2.amazonaws.com/public/admin/web3adress.png"}
                alt={"auccontactadress"}
                width=""
                height=""
              />{" "}
              <span>Founders Business Park</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
