// Import React

// Define the Business component
const Business = () => {
  // Image path
  const imagePath = process.env.REACT_APP_ASSETS_ADMIN;

  // Render the Business component
  return (
    <div className="w-full  h-auto font-[SairaCondensed] overflow-hidden relative p-10">
        <div className="  max-w-4xl mx-auto p-6   bg-opacity-80 shadow-md rounded-lg   w-full flex flex-col gap-20 items-center  md:text-xl">
          <img src={`https://vinhistory.s3.us-west-2.amazonaws.com/public/admin/aucabout1.png`} alt={"aucaboutcars"} />
          <p className="md:w-[50%]">
            Welcome to vehicleread.com - your number one platform for buying and selling used cars.
            We are industry leaders who work 24/7 to find the latest information regarding the used cars published or updated on different websites for sale.
            We collect the most reliable information and data regarding the vehicles: VIN codes and VIN data, vehicle history reports, and pricing information.
          </p>
          <p className="md:w-[50%] md:float-right md:pr-20">
            Our dedicated team of experts and customer service is at your complete disposal. Both car buyers and sellers can contact our support teams and ask the following:
            1. Remove VIN code information (owner’s request only)
            2. Update the vehicle data (in case of wrong information)
            3. Assist with any other queries
          </p>
          <span className="  max-w-4xl mx-auto p-6   bg-opacity-80 shadow-md rounded-lg  flex flex-col md:flex-row w-full pl-[20%] items-center gap-20">
            <img className="w-[100px] h-[100px] " src={`https://vinhistory.s3.us-west-2.amazonaws.com/public/admin/aucabout2.png`} width="" height="" alt={"aucaboutcars"} />
            <span className="flex flex-col gap-10">
              <p className="md:w-[40%]">
                Our company is based on the most important core values that are trust, transparency, and affordability.
                We respect our clients and website visitors and guarantee to provide only accurate and trustworthy data on our website.
              </p>
              <p className="md:w-[40%]">
                vehicleread.com is your best companion when you decide to buy or sell a used car - a platform
                that equips you all the necessary knowledge required for making a very important decision.
              </p>
            </span>
          </span>
        </div>
      </div>
 
  );
};

// Export the Business component
export default Business;
