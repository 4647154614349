import { Fragment, useEffect, useRef, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiMenu, BiX } from "react-icons/bi"; // Import the required icons
import {
  FcBusiness, FcContacts, FcHome, FcServices, FcShipped
} from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import __useClickOutside from "../_useOutsideref/_useOutsideClick";
import { linkClick } from "../handelclicks/link-click";
import { makeModelsData } from "../text-carousel/homeCarosel";
import DropdownMenu from "../dropdown/Dropdown";



// Prepare categories


const Topbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const outsideRef = useRef();
  const outsideMenuRef = useRef();
  const PA = process.env.REACT_APP_ASSETS_ADMIN;






  const handleHover = () => {
    setIsDropdownOpen(true);
  };



  const handleLeave = () => {
    setIsDropdownOpen(false);
  };

  const toggleMenu = () => {
    setShowMenu(true);
  };
  __useClickOutside(outsideRef, () => setIsDropdownOpen(false));
  __useClickOutside(outsideMenuRef, () => setShowMenu(false));
  useEffect(() => {
    // Function to check window dimensions and close the menu if it's open and window size is too big
    const handleWindowResize = () => {
      if (window.innerWidth >= 640) {
        setShowMenu(false);
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      {/* Mobile View */}
      <div className="sm:hidden w-full fixed z-[55] sm:z-0 flex  bg-[#0c0c0c] items-center  h-[70px] ">
        <div className="flex items-center justify-center bg-gradient-to-r  gap-12 text-white font-light">
          <Link to={'/'} className="pl-2" onClick={(e) => {
            e.preventDefault();
            linkClick("/");
          }}>
            <img className="w-10 h-10 2xl:w-20 2xl:h-20 rounded-sm" src={'https://vinhistory.s3.us-west-2.amazonaws.com/public/admin/web-3logo.png'} alt="vehicleread_main_logo" />
          </Link>
          <div className="relative ml-4">
            <div className="inline-block overflow-hidden whitespace-nowrap animate-showup">
              <div className="absolute inset-0 flex items-center justify-center animate-reveal">
                <span className="inline-block animate-slidein font-sans  font-extrabold">VEHICLE READ</span>
              </div>
            </div>
          </div>
        </div>
        <button
          className="fixed top-4 right-5 text-white p-2 bg-gray-800 rounded-full focus:outline-none"
          onClick={toggleMenu}
        >
          {showMenu ? (
            <BiX className="h-6 w-6 hidden" />
          ) : (
            <BiMenu className="h-6 w-6" />
          )}
        </button>
      </div>
      {/* Desktop View */}
      <div className="hidden sm:flex w-full bg-gray-900 p-4 items-center justify-between shadow-md z-50">
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <img className="w-12 h-12 rounded-sm" src={'https://vinhistory.s3.us-west-2.amazonaws.com/public/admin/web-3logo.png'} alt="Logo" />
            <span className="ml-3 text-white text-xl font-bold">VEHICLE READ</span>
          </Link>
        </div>
        <div className="flex items-center space-x-6 text-white">
          <li onClick={() => navigate("/")} className="cursor-pointer flex items-center gap-2 hover:text-gray-400">
            <FcHome className="w-6 h-6" />
            Dashboard
          </li>
          <li
            className="relative cursor-pointer flex items-center justify-center gap-2 hover:text-gray-400"
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
          >
            <FcShipped className="w-6 h-6" />
            Vehicle Info
            {isDropdownOpen && (
              <div className="absolute top-full left-0 mt-2 w-[400px]  bg-white text-black shadow-lg rounded-lg z-50">
                <div className="p-4 flex gap-10" >
                  <div>
                    <h3 className="font-bold mb-2">Makes</h3>
                    <ul>
                      {makeModelsData.map((make, index) => (
                        <Link key={index} to={`/vin-decoder/${make.name?.toLowerCase()}/all-models/all-years?page=1`} className="block hover:text-gray-500">
                          {make.name}
                        </Link>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h3 className="font-bold  mb-2">Models</h3>
                    <ul>
                      {makeModelsData.map((model, index) => (
                        <Link key={index} to={`/vin-decoder/${model.name?.toLowerCase()}/${model.model?.toLowerCase()}/all-years?page=1`} className="block hover:text-gray-500">
                          {model.name} {model.model}
                        </Link>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="p-4">
                  <h3 className="font-bold mt-4 mb-2">Years</h3>
                  <ul>
                    {makeModelsData.map((year, index) => (
                      <Link key={index} to={`/vin-decoder/${year.name?.toLowerCase()}/${year.model?.toLowerCase()}/${year.year}?page=1`} className="block hover:text-gray-500">
                        {year.name} {year.model} {year.year}
                      </Link>
                    ))}
                  </ul>
                </div>

              </div>
            )}
          </li>
          <li onClick={() => navigate("/about")} className="cursor-pointer flex items-center gap-2 hover:text-gray-400">
            <FcBusiness className="w-6 h-6" />
            Our Business
          </li>
          <li onClick={() => navigate("/services")} className="cursor-pointer flex items-center gap-2 hover:text-gray-400">
            <FcServices className="w-6 h-6" />
            Service
          </li>
          <li onClick={() => navigate("/contact")} className="cursor-pointer flex items-center gap-2 hover:text-gray-400">
            <FcContacts className="w-6 h-6" />
            Contact Us
          </li>
        </div>
      </div>

      {/* Off-canvas Menu */}
      {showMenu && (
        <div className="fixed top-0 right-0 w-full h-screen bg-[#131212c7] bg-opacity-70 z-[56] overflow-scroll">
          <div ref={outsideMenuRef} className="w-[60%] sm:w-[30%] bg-[#fff] h-full float-right p-4 flex flex-col gap-5">
            <div className="flex items-center justify-between border-b-[1px] border-[#000] pb-2 mb-2">
              <AiFillCloseCircle className="w-[25px] h-[25px]" onClick={() => setShowMenu(false)} />
              <h3 className='text-[18px]'>Menu</h3>
            </div>
            <ul>
              <li onClick={() => navigate("/")} className="py-2 flex items-center gap-2 cursor-pointer">
                <FcHome className="w-6 h-6" />
                Dashboard
              </li>

              <DropdownMenu makeModelsData={makeModelsData} />

              <Link to='/about'>
                <li
                  className="text-center   py-2 rounded-sm  hover:bg-slate-700 hover:text-gray-400   cursor-pointer relative"
                  onMouseEnter={handleHover}
                  onMouseLeave={handleLeave}
                  ref={outsideRef}
                >
                  <div className="flex items-center gap-2 ">
                    <FcBusiness />
                    Our Business
                  </div>
                </li>
              </Link>
              <Link to="/contact">
                <li className="py-2 flex items-center gap-2">
                  <FcContacts
                    style={{ width: "20px", height: "20px" }}
                    color="#fff"
                  />
                  Contact Us
                </li>
              </Link>
            </ul>

          </div>
        </div>
      )}
    </>
  );
};

export default Topbar;
