
import { FaBitcoin, FaCcMastercard, FaCcPaypal, FaCcStripe, FaCcVisa, FaFacebook, FaLinkedin, FaTelegram } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { HiMail } from "react-icons/hi";
import { SiAmericanexpress, SiGmail } from "react-icons/si";
import { Link } from "react-router-dom";
import { linkClick } from "../handelclicks/link-click";


const Footer = () => {
  const PA = process.env.REACT_APP_ASSETS_ADMIN
  return (
    <div className="px-4 pt-16 items-center  md:px-24 lg:px-8 bg-[#0c0c0c] text-white  ">
      <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6   text-white">
        <div className="grid grid-cols-1 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
          <div>
            <p className="font-semibold tracking-wide ">Navigation</p>
            <ul className="mt-2 space-y-2 ">
              <Link to="/">
                <li>Dashboard</li>
              </Link>
              <Link to="/about">
                <li>Our Business</li>
              </Link>
              <Link to="/contact">
                <li>Contact Us</li>
              </Link>
              <Link to="/FAQ">
                <li>FAQ</li>
              </Link>
            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide ">Blog</p>
            <ul className="mt-2 space-y-2 ">
              <Link to="/vin-decoder/toyota/all-models/all-years?page=1" 
                onClick={(e) => {
                  e.preventDefault();
                  linkClick("/vin-decoder/toyota/all-models/all-years?page=1");
                }} >
                <li>Free Vin Decoder Toyota</li>
              </Link>
              <Link to="/vin-decoder/bmw/all-models/all-years?page=1"   
               
               onClick={(e) => {
                 e.preventDefault();
                 linkClick("/vin-decoder/bmw/all-models/all-years?page=1");
               }}
              >
                <li>Free Vin Decoder BMW</li>
              </Link>
              <Link to="/vin-decoder/audi/all-models/all-years?page=1"   
               
               onClick={(e) => {
                 e.preventDefault();
                 linkClick("/vin-decoder/Audi/all-models/all-years?page=1");
               }}
              >
                <li>Free Vin Decoder Audi</li>
              </Link>
              <Link to="/vin-decoder/mercedes-benz/all-models/all-years?page=1"   
               
               onClick={(e) => {
                 e.preventDefault();
                 linkClick("/vin-decoder/mercedes-benz/all-models/all-years?page=1");
               }}
              >
                <li>Free Vin Decoder Mercedes Benz</li>
              </Link>
              <Link to="/vin-decoder/hyundai/all-models/all-years?page=1"   
               
               onClick={(e) => {
                 e.preventDefault();
                 linkClick("/vin-decoder/hyundai/all-models/all-years?page=1");
               }}
              >
                <li>Free Vin Decoder Hyundai</li>
              </Link>
              <Link to="/vin-decoder/lexus/all-models/all-years?page=1"   
               
               onClick={(e) => {
                 e.preventDefault();
                 linkClick("/vin-decoder/lexus/all-models/all-years?page=1");
               }}
              >
                <li>Free Vin Decoder Lexus</li>
              </Link>
              <Link to="/vin-decoder/volkswagen/all-models/all-years?page=1"   
               
               onClick={(e) => {
                 e.preventDefault();
                 linkClick("/vin-decoder/volkswagen/all-models/all-years?page=1");
               }}
              >
                <li>Free Vin Decoder Volkswagen</li>
              </Link>
            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide ">Policy</p>
            <ul className="mt-2 space-y-2 ">
              <Link to="/terms">
                <li>Terms & Conditions</li>
              </Link>
              <Link to="/privacy">
                <li>Privacy Policy</li>
              </Link>
            </ul>
          </div>
          <div className="">
            <p className="font-semibold tracking-wide">Contact</p>
            <div className="mt-2 space-y-2 flex items-center gap-2">
              <span><SiGmail size="25" className="text-red-700" /></span>
              <span className="whitespace-nowrap">auchistory@gmail.com</span>
            </div>
            <div className="mt-2 space-y-2 flex items-center gap-2">
              <span><FaFacebook size="25" className="text-blue-700" /></span>
              <a href="https://www.facebook.com/profile.php?id=61556277761425" target="_blank" className="whitespace-nowrap">auchistory FB</a>
            </div>
            <div className="mt-2 space-y-2 flex items-center gap-2">
              <span><FaLinkedin size="25" className="text-blue-500" /></span>
              <a href="https://www.linkedin.com/company/auchistory/" target="_blank" className="whitespace-nowrap">auchistory</a>
            </div>
            <div className="mt-2 space-y-2 flex items-center gap-2">
              <span><FaPhone size="25" color="green" /></span>
              <a href="tel:+1845-394-5380" target="_blank" className="whitespace-nowrap">(+1)845-394-5380</a>
            </div>
            <div className="mt-2 space-y-2 flex items-center gap-2">
              <FaTelegram size="25" className="text-blue-300" />
              <a href="https://t.me/auchistoryinfo" target="_blank" >
                @auchistory
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 p-3  md:items-center">
          <Link to="/">
            <img className="rounded-full" src={'https://vinhistory.s3.amazonaws.com/public/admin/web-3logo.png'} width="50px" height="50px" alt="vehicleread-logo" />
          </Link>
          <span className="text-white font-bold text-[13px]"> Payment Methods </span>
          <div className="footer-payment-method flex gap-2 ">
            <FaCcPaypal size='50' className="text-yellow-500" />
            <FaCcMastercard size='50' className="text-cyan-800 " />
            <FaCcVisa size='50' className="text-blue-800 " />
          </div>
          <div className="footer-payment-method flex gap-2 ">
            <SiAmericanexpress size='50' />
            <FaBitcoin size='50' className="text-orange-900" />
            <FaCcStripe size='50' className="text-blue-500 " />
          </div>

        </div>
        <div className="dmca-logo">
          <a href="//www.dmca.com/Protection/Status.aspx?ID=ebd566c4-5cb5-4583-90b3-5a1605a6676e" target="blank" title="DMCA.com Protection Status" className="dmca-badge" >
            <img src="https://images.dmca.com/Badges/dmca-badge-w250-5x1-01.png?ID=ebd566c4-5cb5-4583-90b3-5a1605a6676e" width="150px" height="50px" alt="DMCA.com Protection Status" /></a>
          <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"> </script>
        </div>
      </div>
      <div className="flex flex-col justify-between pt-5 pb-10 border-t-2 border-green-700 sm:flex-row">
        <div className="p-2 w-[60%] text-[12px]">
          <h3>vehicleread - VIN Codes, Vehicle History​</h3>
          <p>Contact us for any inquiries related to VIN codes and vehicles presented on our websites. Get in touch with our customer support team at any time throughout the week. </p>
          <p>Website for finding VIN history of any used vehicle available for sale on the market. Search millions of cars with just a VIN code and get all the information you need - past accidents, ownership, damages and repairs, odometer readings, and more. </p>
        </div>

        <div className="flex items-center mt-4 space-x-4 sm:mt-0">
          <p className="text-sm ">© Copyright 2024 vehicleread </p>
          <a
            href="https://mail.google.com/mail/u/0/#inbox?compose=new"
            target="_blank"
            className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            <HiMail style={{ width: "30px", height: "30px" }} />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Footer;
