import React from "react";
import styles from "./PaymentForm.module.css";

import * as PAYPAL from "@paypal/react-paypal-js";
import * as IO from "react-icons/io";
import useMyContext from "../../context/useMyContext";





const PaymentForm = ({ setPaypal }) => {
  const [message, setMessage] =  React.useState("");
  const { carId } =  useMyContext();
  const [input, setInput] =React.useState({ payer_name: "", email: "" });
  const [loadingPaypal, setLoadingPaypal] = React.useState(false);



  async function createOrderCallback(carId) {

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/checkout/auc/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // use the "body" param to optionally pass additional order information
        // like product ids and quantities
        body: JSON.stringify({
          cart: [
            {
              id: carId,
              hidden_from: "Auchistory",
              quantity: "1",
            },
          ],
        }),
      });
      const orderData = await response.json();

      if (orderData.id) {
        return orderData.id;
      } else {
        const errorDetail = orderData?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
          : JSON.stringify(orderData);

        throw new Error(errorMessage);
      }
    } catch (error) {

      return `Could not initiate PayPal Checkout...${error}`;
    }
  }

  async function onApproveCallback(input, carId, data, actions) {

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/checkout/auc/orders/${data.orderID}/capture`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: [
            {
              id: carId,
              email: input?.email,
              payer_name: input?.payer_name,
              hidden_from: "Auchistory",
              quantity: "1",
            },
          ],
        }),
      });

      const orderData = await response.json();
      // Three cases to handle:
      //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
      //   (2) Other non-recoverable errors -> Show a failure message
      //   (3) Successful transaction -> Show confirmation or thank you message

      const transaction =
        orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
        orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
      const errorDetail = orderData?.details?.[0];

      // this actions.restart() behavior only applies to the Buttons component
      if (errorDetail?.issue === "INSTRUMENT_DECLINED" && !data.card && actions) {
        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
        return actions.restart();
      } else if (
        errorDetail ||
        !transaction ||
        transaction.status === "DECLINED"
      ) {
        // (2) Other non-recoverable errors -> Show a failure message
        let errorMessage;
        if (transaction) {
          errorMessage = `Transaction ${transaction.status}: ${transaction.id}`;
        } else if (errorDetail) {
          errorMessage = `${errorDetail.description} (${orderData.debug_id})`;
        } else {
          errorMessage = JSON.stringify(orderData);
        }

        throw new Error(errorMessage);
      } else {
        // (3) Successful transaction -> Show confirmation or thank you message
        // Or go to another URL:  actions.redirect('thank_you.html');

        return `Transaction ${transaction.status}: ${transaction.id}.`;
      }
    } catch (error) {
      return `Sorry, your transaction could not be processed...${error}`
    }
  }

  const SubmitPayment = ({ input, carId, onHandleMessage, setLoadingPaypal }) => {
    // Here declare the variable containing the hostedField instance

    const { cardFields } = PAYPAL.usePayPalHostedFields();
    const cardHolderName = React.useRef(null);


    const submitHandler = () => {
      setLoadingPaypal(true);
      if (typeof cardFields.submit !== "function") return; // validate that \`submit()\` exists before using it
      //if (errorMsg) showErrorMsg(false);

      cardFields
        .submit({
          // The full name as shown in the card and billing addresss
          // These fields are optional for Sandbox but mandatory for production integration
          cardholderName: cardHolderName?.current?.value,

        })
        .then(async (data) => {
          setLoadingPaypal(false)
          onHandleMessage(await onApproveCallback(input, carId, data))
        })
        .catch((orderData) => {

          setLoadingPaypal(false);
          onHandleMessage(
            `Sorry, your transaction could not be processed...${JSON.stringify(
              orderData,
            )}`,
          );
        });
    };

    return (
      <button onClick={submitHandler} className="bg-blue-500 w-full  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Pay
      </button>
    );
  };

  const Message = ({ content }) => {
    return <div className=" overflow-y-auto overflow-x-hidden h-[5%] scroll">{content}</div>;
  };









  const customErrorHandler = (error) => {
    if (error && error.message === 'ppxo_inline_guest_unhandled_error') {
      // Handle this specific error
      console.error('Caught ppxo_inline_guest_unhandled_error:', error);
    } else {
      // For other errors, log them normally
      console.error(error);
    }
  };

  // Override the global console.error with the custom error handler
  console.error = customErrorHandler;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInput((prev) => ({ ...prev, [name]: value }))
  }

  const handleClosePaypalModal = () => {
    setPaypal(false);
  }

  return (
    <div className={styles.payform}>
      <button className="float-right" onClick={handleClosePaypalModal}><IO.IoIosClose size={40} /></button>
      <PAYPAL.PayPalButtons
        style={{
          shape: "rect",
          layout: "vertical",
        }}
        styles={{ marginTop: "4px", marginBottom: "4px" }}
        createOrder={() => createOrderCallback(carId)}
        onApprove={async (data) => setMessage(await onApproveCallback(input, carId, data))}
      />
      <PAYPAL.PayPalHostedFieldsProvider createOrder={() => createOrderCallback(carId)}>


        <div style={{ marginTop: "4px", marginBottom: "4px" }}>
          <PAYPAL.PayPalHostedField
            id="card-number"
            hostedFieldType="number"
            options={{
              selector: "#card-number",
              placeholder: "Card Number",
            }}
            className={styles.payinput}
          />
          <div className={styles.container}>
            <PAYPAL.PayPalHostedField
              id="expiration-date"
              hostedFieldType="expirationDate"
              options={{
                selector: "#expiration-date",
                placeholder: "Expiration Date",
              }}
              className={styles.payinput}
            />
            <PAYPAL.PayPalHostedField
              id="cvv"
              hostedFieldType="cvv"
              options={{
                selector: "#cvv",
                placeholder: "CVV",
              }}
              className={styles.payinput}
            />
          </div>

          <SubmitPayment input={input} carId={carId} onHandleMessage={setMessage} setLoadingPaypal={setLoadingPaypal} />
        </div>
      </PAYPAL.PayPalHostedFieldsProvider>
      {loadingPaypal && <div>Loading...</div>}
      <Message content={message} />
    </div>
  );
};
export default PaymentForm;