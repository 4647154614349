import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { linkClick } from '../handelclicks/link-click';
import { makeModelRequest } from '../../utils/requestMethods';

const ModelEnum = () => {

    const params = useParams();
    const { make } = params;
    const [models, setModels] = useState();
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        async function getModels() {
            try {
                const response = await makeModelRequest.get(`/make-models?make=${make}`);
                setModels(response.data);
            } catch (err) {
                console.log(err)
            }
        }
        if (make) {
            getModels();
        }
    }, [make])


    const handleToggle = () => {
        setShowAll(!showAll);
    };


    return (
        <div className="container flex flex-col mx-auto p-4">
            <span className="text-xl font-bold text-center mb-6">Available Models for {make}</span>
            <ul className="grid grid-cols-1 gap-4">
                {models?.slice(0, showAll ? models?.length : 3)?.map((model, index) => (
                    <li key={index} className="bg-white shadow-lg rounded-lg p-4 transition transform hover:scale-105">
                        <Link to={`/vin-decoder/${make}/${model?.model}/all-years?page=1`} onClick={() => linkClick(`/vin-decoder/${make}/${model?.model}/all-years?page=1`)}>
                            <span className="text-xl font-semibold mb-2">{model?.model}</span>
                        </Link>
                    </li>
                ))}
            </ul>
            {models?.length > 3 && (
                <div className="flex justify-center mt-4">
                    <button
                        onClick={handleToggle}
                        className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out transform hover:scale-110"
                    >
                        {showAll ? 'Show Less ▲' : 'Show More ▼'}
                    </button>
                </div>
            )}
        </div>
    )
}

export default ModelEnum;
