import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { AiOutlineSearch } from "react-icons/ai";
import { BsClipboardDataFill } from "react-icons/bs";
import { PiBinocularsDuotone } from "react-icons/pi";

import requestUtils from "../../utils/requestUtils";
import { Button } from "@material-tailwind/react";
import { generate_h1_tag } from "../../utils/generate-tags";
import { handleImageError, pictureSrcUpdate } from "../../utils/imgsrc-utils";

import VinEnum from "../../components/suggest/VinPage";
import YearEnum from "../../components/suggest/YearPage";
import MakesEnum from "../../components/suggest/MakesPage";
import ModelEnum from "../../components/suggest/ModelPage";
import { linkClick } from "../../components/handelclicks/link-click";
import Breadcrumbs from "../../components/breadcrumbs/BreadCrumbs";
import SearchProduct from "../../components/searchProduct/SearchProduct";
import ProductListskeleton from "../../components/skeleton/ProductListskeleton";
import VinDecoderTextHeader from "../../components/vindecoder/VinDecoderTextHeader";
import VinDecoderTextBottom from "../../components/vindecoder/VinDecoderTextBottom";
import { helmetDescription, helmetNextURL, helmetTitle, helmetURL } from "../../components/helmet/ProductsDescription";
import VehicleCarousel from "../../components/text-carousel/TextCarousel";
import Pagination from "../../components/pagination/Pagination";
import MainPagination from "../../components/pagination/MainPagination";


const ProductsPage = ({ initdata }) => {

    const navigate = useNavigate();
    const params = useParams();
    const { make, model, year } = params;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const pageQueryParam = queryParams.get('page');
    const [vehicleProducts, setVehicleProducts] = useState(initdata || []);
    const [vehiclePageCount, setVehiclePageCount] = useState(parseInt(pageQueryParam) || 1);
    const [vehicleProductsLoading, setVehicleProductsLoading] = useState(true);
    const [vehiclePageLoading, setVehiclePageLoading] = useState(false);
    const [vehiclePagelength, setVehiclePagelength] = useState(null);
    const [error, setError] = useState(false);
    const [datalength, setDataLength] = useState(null);
    const { pathname } = useLocation();


    useEffect(() => {
        // Check if the `page` query parameter is present
        if (!pageQueryParam || pageQueryParam === "null") {

           navigate(`${location.pathname}?page=1`);
        }
    }, [location, pageQueryParam]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // const handleLoadMore = (pageChange) => {
    //     const newPageCount = vehiclePageCount + 1;
    //     setVehiclePageCount(newPageCount);
    //     setVehiclePageLoading(true);
    //     navigate(`${location.pathname}?page=${newPageCount}`);
    // };
    useEffect(() => {
        try {
            if (location.pathname == "/vin-decoder") {
                requestUtils._getProductsByUpdate("all-makes", "all-models", "all-years", vehiclePageCount).then((data) => {
                    if (data?.data) {
                        setVehicleProductsLoading(false);
                        setVehicleProducts((prevProducts) => {
                            const idsSet = new Set(prevProducts?.map((item) => item?.id));
                            const vinsSet = new Set(prevProducts?.map((item) => item?.vin));
                            const uniqueData = data?.data?.filter((item) => !idsSet?.has(item?.id) && !vinsSet?.has(item?.vin) && item?.id);
                            return [...prevProducts, ...uniqueData];
                        });
                        setVehiclePageLoading(false);
                        setDataLength(data?.totalLength);
                        setVehiclePagelength(data?.totalPages);
                        setError(false);

                    }
                });
            } else {
                requestUtils._getProductsByUpdate(make, model, year, vehiclePageCount).then((data) => {
                    if (data?.data) {
                        setVehicleProductsLoading(false);
                        setVehicleProducts((prevProducts) => {
                            const idsSet = new Set(prevProducts?.map((item) => item?.id));
                            const vinsSet = new Set(prevProducts?.map((item) => item?.vin));
                            const uniqueData = data?.data?.filter((item) => !idsSet?.has(item?.id) && !vinsSet?.has(item?.vin) && item?.id);
                            return [...prevProducts, ...uniqueData];
                        });
                        setVehiclePageLoading(false);
                        setDataLength(data?.totalLength);
                        setVehiclePagelength(data?.totalPages);
                        setError(false);
                    } else {
                        setError(true);
                        setVehicleProductsLoading(false);
                    }
                });
            }
        } catch (err) {

            console.log(err);
        }
    }, [vehiclePageCount, vehicleProductsLoading, make, model, year, error]);

    useEffect(() => {

        setTimeout(() => {
            setVehicleProductsLoading(false);
        }, 15000);
    }, [vehicleProductsLoading])



    const crumbs = () => {
        if (location.pathname == '/vin-decoder') {
            return [
                { label: 'Home', path: '/' },
                { label: `Vin Decoder`, path: `/vin-decoder` },
            ];
        } else if (make !== 'all-makes' && model === "all-models" && year === "all-years") {
            return [
                { label: 'Home', path: '/' },
                { label: `Vin Decoder`, path: `/vin-decoder` },
                { label: make, path: `/vin-decoder/${make?.toLowerCase()}/all-models/all-years` },
            ];
        }
        else if (make !== "all-makes" && model !== "all-models" && year === "all-years") {
            return [
                { label: 'Home', path: '/' },
                { label: `Vin Decoder`, path: `/vin-decoder` },
                { label: make, path: `/vin-decoder/${make?.toLowerCase()}/all-models/all-years` },
                { label: model, path: `/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/all-years` },

            ];
        }
        else if (make !== "all-makes" && model !== "all-models" && year !== "all-years") {
            return [
                { label: 'Home', path: '/' },
                { label: `Vin Decoder`, path: `/vin-decoder` },
                { label: make, path: `/vin-decoder/${make?.toLowerCase()}/all-models/all-years` },
                { label: model, path: `/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/all-years` },
                { label: year, path: `/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}` },
            ];
        } else {
            return [
                { label: 'Home', path: '/' },]
        }
    }
    const ltdJson =
    {
        "@context": "http://schema.org",
        "@type": "Service",
        "serviceType": "Free VIN Decoder",
        "name": "Free VIN Decoder Service",
        "description": "Use our free VIN decoder to check vehicle history and specifications across all makes and models. Simply enter the VIN to get started.",
        "url": "https://vehicleread.com/vin-decoder/",
        "areaServed": {
            "@type": "Country",
            "name": "Worldwide"
        },
        "provider": {
            "@type": "Organization",
            "name": "vehicleread",
            "url": "https://vehicleread.com"
        }
    }

    const decodedModel = encodeURIComponent(model);
    const decodedMake = encodeURIComponent(make);

    return (
        <>
            <Helmet>
                <title>{(make && model && year) == undefined ? "Vin Decoder - Auction cars history" : helmetTitle(make, model, year)}</title>
                <meta name="title" content={(make && model && year) == undefined ? "Vin Decoder - Auction cars history" : helmetTitle(make, model, year)} />

                <meta name="description" content={(make && model && year) == undefined ? "Vin Decoder - Lookup any car's VIN numbers for FREE. Simply enter the VIN to check vehicle history, car specifications, crash reports, and much more." : helmetDescription(make, model, year)} />
                <meta name="robots" content="index, follow" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={(make && model && year) == undefined ? `https://vehicleread.com/vin-decoder?page=${parseInt(pageQueryParam)}` : helmetURL(parseInt(pageQueryParam), make, model, year)} />
                <meta property="og:title" content={(make && model && year) == undefined ? "Vin Decoder - Auction cars history" : helmetTitle(make, model, year)} />
                <meta property="og:description" content={(make && model && year) == undefined ? "Vin Decoder - Lookup any car's VIN numbers for FREE. Simply enter the VIN to check vehicle history, car specifications, crash reports, and much more." : helmetDescription(make, model, year)} />
                <meta property="og:image" content="https://vinhistory.s3.amazonaws.com/public/admin/web-3logo.png" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={(make && model && year) == undefined ? `https://vehicleread.com/vin-decoder?page=${parseInt(pageQueryParam)}` : helmetURL(parseInt(pageQueryParam), make, model, year)} />
                <meta property="twitter:title" content={(make && model && year) == undefined ? "Vin Decoder - Auction cars history" : helmetTitle(make, model, year)} />
                <meta property="twitter:description" content={(make && model && year) == undefined ? "Vin Decoder - Lookup any car's VIN numbers for FREE. Simply enter the VIN to check vehicle history, car specifications, crash reports, and much more." : helmetDescription(make, model, year)} />
                <meta property="twitter:image" content="https://vinhistory.s3.amazonaws.com/public/admin/web-3logo.png" />

                {parseInt(pageQueryParam) !== 1 && <link rel="prev" href={location.pathname == "/vin-decoder" ? `https://vehicleread.com/vin-decoder?page=${parseInt(pageQueryParam) - 1}` : helmetNextURL((parseInt(pageQueryParam) - 1), make, model, year)} />}
                <link rel="next" href={location.pathname == "/vin-decoder" ? `https://vehicleread.com/vin-decoder?page=${parseInt(pageQueryParam) + 1}` : helmetNextURL((parseInt(pageQueryParam) + 1), make, model, year)} />

                {location.pathname == "/vin-decoder" ? <link rel="alternate" hreflang="en" href={`https://vehicleread.com/vin-decoder?page=${pageQueryParam}`} /> : <link rel="alternate" hreflang="en" href={`https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}?page=${pageQueryParam}`} />}
                {location.pathname == "/vin-decoder" ? <link rel="alternate" hreflang="x-default" href={`https://vehicleread.com/vin-decoder?page=${pageQueryParam}`} /> : <link rel="alternate" hreflang="x-default" href={`https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}?page=${pageQueryParam}`} />}
                {location.pathname == "/vin-decoder" ? <link rel="canonical" href={`https://vehicleread.com/vin-decoder?page=${pageQueryParam}`} /> : <link rel="canonical" href={`https://vehicleread.com/vin-decoder/${make?.toLowerCase()}/${model?.toLowerCase()}/${year}?page=${pageQueryParam}`} />}


                {location.pathname == "/vin-decoder" ? < script type="application/ld+json" >
                    {JSON.stringify(ltdJson)}

                </script > : ""}

            </Helmet>

            <SearchProduct />

            <div className="bread-crumbs-list  items-start pt-10   border-b-2 border-b-[#d1d5db]   md:text-base ">
                <Breadcrumbs crumbs={crumbs()} />
            </div>


            <div className=" bg-gradient-to-b from-[#f6f8ff] to-[#f1f4ff] overflow-hidden ">
                <VinDecoderTextHeader />




                <div className="w-full flex flex-col pt-12 2xl:flex-row    gap-3 p-5  ">
                    {pathname === `/vin-decoder` && <div className="2xl:w-[20%] m-[60px] ">
                        <MakesEnum />
                    </div>
                    }
                    {pathname === `/vin-decoder/${decodedMake}/all-models/all-years` && decodedMake !== "all-models" && <div className="2xl:w-[20%] m-[60px]">
                        <ModelEnum />
                    </div>
                    }

                    {pathname === `/vin-decoder/${decodedMake}/${decodedModel}/all-years` && decodedModel !== "all-models" && <div className="2xl:w-[20%] m-[60px]">
                        <YearEnum />
                    </div>
                    }

                    {pathname === `/vin-decoder/${decodedMake}/${decodedModel}/${year}` && decodedModel !== "all-models" && year !== "all-years" && <div className="2xl:w-[20%] m-[60px]">
                        <VinEnum />
                    </div>
                    }

                    <div>
                        <div className=" w-full md:w-[896px] flex justify-between  gap-2 md:gap-0 rounded-[4px] border-b p-3">
                            <span className="pt-3 flex items-center gap-1">
                                <AiOutlineSearch />
                                {(make && model && year) == undefined
                                    ?
                                    "Free Vin Decoder"
                                    :
                                    pathname === `/vin-decoder/${make}/all-models/all-years` ? <h2 className="text-[8px] sm:text-[14px]">{generate_h1_tag(make, model, year)}</h2> : <h1 className="text-[8px] sm:text-[14px]">{generate_h1_tag(make, model, year)}</h1>}
                            </span>
                            <span className="pt-3 flex items-center gap-1">
                                <BsClipboardDataFill />
                                <span className=" flex items-center text-[8px] sm:text-[14px]">Found:{new Intl.NumberFormat().format(datalength)}</span>
                            </span>
                        </div>
                        {error && <div className="pt-10 flex flex-col justify-center items-center gap-2 p-3"><PiBinocularsDuotone color="blue" size="30" />
                            <p>Not Found Any Result For {`${make} ${model} ${year}`}</p>
                            <br />
                            <Link to={`/vin-decoder?page=1`} onClick={(e) => {
                                e.preventDefault()
                                linkClick(`/vin-decoder?page=1`)
                            }
                            }
                            ><Button className="bg-[#3333] text-black">Back To Vin Decoder</Button></Link>
                        </div>}

                        {vehicleProducts?.filter(i => i !== undefined)?.map((items) => {
                            return (<Fragment key={items?.id}>
                                <div className="productCard border-b-2 border-green-700 rounded-[3px] w-[100%]">
                                    <div className="flex flex-col justify-center">
                                        <div className="relative flex flex-col p-2 md:flex-row  border-gray-500 md:space-x-5 space-y-3 md:space-y-0   max-w-xs md:max-w-4xl md:h-[200px] mx-auto   ">
                                            <div className="w-full md:w-[250px] h-full  ">
                                                <img
                                                    src={pictureSrcUpdate(items)}
                                                    alt={`VIN ${items?.vin} ${items?.name?.replace(`${items?.vin}`, '')} no.1`}
                                                    className="rounded-[10px] w-[80%] md:w-full h-[170px]"
                                                    height="170px"
                                                    width="300px"
                                                    onError={handleImageError}
                                                />
                                            </div>
                                            <div className="  flex flex-col md:flex-row items-start gap-2 md:gap-5 ">
                                                <div className="flex flex-col items-start text-nowrap whitespace-nowrap   border-gray-500  w-[25em] p-3 h-[170px] gap-2">
                                                    <div className="flex w-[50%] md:w-full gap-10 md:gap-0 md:flex-row md:justify-between">
                                                        <h3 className="  md:text-[18px] text-[12px] text-blue-800 font-bold ">
                                                            {items?.name}
                                                        </h3>
                                                    </div>
                                                    <div className="md:text-[14px] text-gray-500 text-base flex flex-col md:flex-row md:gap-20">
                                                        <div className="flex flex-col items-start md:w-[60%]  text-[#000]">
                                                            <p className=" flex items-center gap-1 whitespace-nowrap">
                                                                <span>Vin:</span>
                                                                <span className="">✔️{items?.vin?.toUpperCase()}</span>
                                                            </p>

                                                            <p className="whitespace-nowrap">
                                                                <span>Primary: </span>
                                                                <span className="">
                                                                    {items?.primary_damage}
                                                                </span>
                                                            </p>

                                                            <p>
                                                                <span>Odometer: </span>
                                                                <span className=" whitespace-nowrap">
                                                                    {items?.odometer_to_string ? items?.odometer_to_string + (items?.odometer_to_string?.includes("mi") ? '' : ' (mi)') : items?.odometer + "(mi)"}
                                                                </span>
                                                            </p>
                                                            <p className="whitespace-nowrap">
                                                                <span>Make: </span>
                                                                <span className="">
                                                                    {items?.makes}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col items-center gap-3 md:w-[200px]">
                                                    <p>
                                                        {items?.price === "0" && !items?.price && !items?.final_bid_formatted ? (
                                                            <>
                                                                <span className="font-normal text-[9px] md:text-[13px] ">
                                                                    0.00
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="text-gray-500">
                                                                    Final Price:
                                                                </span>
                                                                <span className="font-normal ">
                                                                    ${""}
                                                                    {items?.final_bid_formatted === "---" || !items?.final_bid_formatted
                                                                        ? "0.00"
                                                                        : items?.final_bid_formatted}
                                                                </span>
                                                            </>
                                                        )}
                                                    </p>
                                                    <Link to={`/vin-decoder/${items?.makes?.toLowerCase()}/${items.model?.toLowerCase()}/${items?.car_age}/${items?.vin}`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            linkClick(`/vin-decoder/${items?.makes?.toLowerCase()}/${items?.model?.toLowerCase()}/${items?.car_age}/${items?.vin}`);
                                                        }}
                                                    >
                                                        <Button
                                                            className="bg-[#3333] text-black"
                                                        >
                                                            Show More
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                            )
                        })}
                        {vehicleProducts?.length !== 0 && vehiclePageCount !== vehiclePagelength && !vehiclePageLoading && !error && (
                            <div
                                className="flex items-center justify-center w-full p-5"
                            >
                                {pathname === "/vin-decoder" ? <MainPagination currentPage={vehiclePageCount} totalPages={50} /> : <Pagination currentPage={vehiclePageCount} totalPages={50} params={{ make, model, year }} />}
                            </div>
                        )}
                        {vehiclePageLoading && Array.from({ length: 5 }).map((v, i) => (<Fragment key={i}><ProductListskeleton /> </Fragment>)
                        )}

                        {vehicleProductsLoading && Array.from({ length: 5 }).map((v, i) => (<Fragment key={i}><ProductListskeleton /></Fragment>))}
                    </div>
                </div>
                <VinDecoderTextBottom />
                <div className="bg-gradient-to-b from-[#f6f8ff] to-[#f1f4ff]">

                    <VehicleCarousel />
                </div>
            </div>
        </>
    );
};

export default ProductsPage;
