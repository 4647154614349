
const ProductListskeleton = () => {
  return (
        <div className="productCard border-b rounded-[3px] animate-pulse w-[100%]">
                  <div className="flex flex-col justify-center">
                    <div className="relative flex flex-col p-2 md:flex-row  border-gray-500 md:space-x-5 space-y-3 md:space-y-0   max-w-xs md:max-w-4xl md:h-[200px] mx-auto   ">
                      <div className="w-full md:w-[250px] h-full  ">
                        <div
                          className="rounded-[10px] w-[80%]  items-center bg-gray-600   md:w-full  h-[170px]"
                        />
                      </div>
                      <div className="  flex flex-col md:flex-row items-start gap-5   ">
                        <div className="flex flex-col items-start   border-gray-500  w-[25em] p-3 h-[170px] gap-2">
                          <div className="flex w-[60%] md:w-[80%] justify-between items-center bg-gray-600  ">
                       <div className="h-3 w-12 md:w-20  items-center bg-gray-600 "/>
                          </div>
                            <div className="flex flex-col w-40 h-3   bg-gray-600 items-start md:w-[60%] "/>
                            <div className="flex flex-col w-40 h-3   bg-gray-600 items-start md:w-[60%] "/>
                            <div className="flex flex-col w-40 h-3   bg-gray-600 items-start md:w-[60%] "/>
                            <div className="flex flex-col w-40 h-3   bg-gray-600 items-start md:w-[60%] "/>
                          </div>
                        <div className="flex flex-col items-center justify-center gap-3  md:w-[200px]">
                          <p className='w-40 h-4 items-center bg-gray-600 ' >
                          </p>
                          <p className='w-40 h-10 rounded items-center bg-gray-600 ' >                           
                           </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  )
}

export default ProductListskeleton
