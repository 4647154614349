import React from "react";
import * as AI from "react-icons/ai";
import { handleImageError, pictureSrcForProduct } from "../../utils/imgsrc-utils";
const CustomSingleSlider = ({ item ,currentImage}) => {
  const PA = process.env.REACT_APP_ASSETS_BASE_URL_WITHOUT_SLASH;
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

 
   React.useEffect(() => {
    setCurrentImageIndex(currentImage);
   },[currentImage])

  
  const goToImage = (index) => {
    setCurrentImageIndex(index);
  };
  return (
    <div className="relative  sm:max-w-[50%] overflow-hidden ">
    <div
      className="flex transition-transform duration-500"
      style={{
        transform: `translateX(-${currentImageIndex * 100}%)`,
      }}
    >
        {item?.imgUrls && item?.imgUrls.map((image, index) => (
       
          <img
            key={index}
            src={pictureSrcForProduct(item , image)}
            alt={`VIN ${item?.vin} ${item?.name?.replace(`${item?.vin}`, '')} no.${index + 1}`}
            
            className="w-full h-full "
            height="auto"
            width="auto"
            onError={handleImageError}
          />
          
        ))}
      </div>

      {/* Navigation dots */}
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {item?.imgUrls && item?.imgUrls?.map((_, index) => (
          <button
            key={index}
            onClick={() => goToImage(index)}
            className={`w-2 h-2 rounded-full bg-gray-300 ${
              index === currentImageIndex ? "bg-gray-600" : ""
            }`}
          />
        ))}
      </div>

      {/* Navigation arrows */}
      <button
        className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white bg-[] p-2 rounded-full"
        onClick={() =>
          setCurrentImageIndex(
            (prevIndex) =>
              (prevIndex - 1 + item?.imgUrls?.length) %
              item?.imgUrls?.length
          )
        }
      >
        <AI.AiOutlineArrowLeft />
      </button>
      <button
        className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white bg-[] p-2 rounded-full"
        onClick={() =>
          setCurrentImageIndex(
            (prevIndex) => (prevIndex + 1) % item?.imgUrls?.length
          )
        }
      >
        <AI.AiOutlineArrowRight />
      </button>
    </div>
  );
};

export default CustomSingleSlider;
