import { Component } from "react";

class Toyota extends Component{
    Toyota_header() {
        return (

            <div className=' max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg flex flex-col justify-center gap-5 md:w-[80%] items-center '>
                
                <div className='  decoder-header flex flex-col justify-center  md:w-2/3'>
                    <h1 className='text-[18px] font-bold'>Toyota VIN Decoder</h1>
                    <p>
                        Our free Toyota VIN decoder helps you gather information regarding any vehicle published for sale online.
                        Whether it is an odometer reading or damage records you can get them for free on our website.
                        For easier access, we recommend that you keep our page in your bookmarks so that you can find the vin decoder tool as soon as you need it.
                    </p>
                </div>
            </div>
        )
    }
    Toyota_bottom() {
        return (
        <div className='max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg'>
            <h3 className='text-3xl font-bold text-center mb-6'>Free Toyota VIN Decoder Tool </h3>
            <p>Our website provides free decoder tools for people with different goals and purposes.
                Whether you are a future car buyer, you are selling your Toyota car,
                or you are just researching pre-owned Toyota vehicles,
                our free VIN decoder tool can help you find all relevant information.
            </p>
            <p>Simply provide the VIN code you are looking an information about and get data regarding:</p>
            <ol className='list-disc list-inside mb-6 text-gray-700'>
                <li>Year when the car was built</li>
                <li>Odometer readings</li>
                <li>Primary and secondary damages</li>
                <li>Types of keys</li>
                <li>Engine information</li>
                <li>Fuel capacity</li>
                <li>Lot information</li>
                <li>Price (market price online)</li>
            </ol>
            <h4 className='text-[18px] font-bold'>How do I decode a Toyota VIN number?</h4>
            <p>You have to know the VIN code of the Toyota vehicle that you are looking for.
                Navigate to the homepage of our website or go directly to the Toyota VIN decoder page.
                You can paste the VIN code and in case the VIN information is available in our database you will get your free reports.
                There are no further steps. </p>
            <h5 className='text-[18px] font-bold'> How to check Toyota model year?</h5>
            <p> The easiest way to check Toyota model year is to search the car with the VIN code. Toyota VIN decoders are one of the best tools to help you check Toyota model year, the date of car manufacturing, or any other relevant data you may need.</p>
            <h6 className='text-[18px] font-bold'>How can I tell when my Toyota was built?</h6>
            <p> There are two ways to tell when your Toyota vehicle was built.</p>
            <p>1. Check your TechPassport - your vehicle passport includes significant information about your car. This information also consists of the year of manufacture and the official date car was released. </p>
            <p>2. VIN decoder - VIN decoders are tools that search for vehicle information based on the VIN presented. They provide different types of information including the manufacturer, dates, and vehicle history reports.</p>
        </div>
        )
    }
}

export default new Toyota();
