const Terms = () => {
  return (
    <div className="container mx-auto p-8">
      <div className="mb-8">
        <h1 className="text-4xl font-bold mb-4 text-indigo-700">Terms and Conditions</h1>
        <h2>§ 1 DEFINITIONS</h2>
        <p className="text-lg">
          vehicleread - privately held company which is operating worldwide under the legal name vehicleread Ltd.
          vehicleread is the official brand name of the legal entity and has the website under the same name: vehicleread.com
        </p>
      </div>
      <div className="mb-8">
        <p>
          Vehicle - motor vehicle presented on the website of vehicleread with its specific VIN code, details, and vehicle information
        </p>
        <p>
          Terms and conditions - present document
        </p>
        <p>
          Website - internet platform entirely owned and held by vehicleread at  <a href="https://vehicleread.com">https://vehicleread.com</a> governed by the set of rules documented by Terms and Conditions and used for electronic services
        </p>
        <p>
          Electronic services - services that are offered by vehicleread at  <a href="https://vehicleread.com">https://vehicleread.com</a> in accordance with the terms and conditions outlined in this document
        </p>
        <p>
          User - any physical entity accessing the services of the vehicleread through the website and under the rules of the current document
        </p>
        <p>
          Customer - any entity engaging in paid services (such as VIN removal services)
        </p>
      </div>
      <div className="mb-8">
        <h2>§ 2 GENERAL PROVISIONS</h2>
        <p>
          Terms and Conditions (current document) outlines the rights and obligations of the users, customers, and the owners of the website provided electronically through the official website of vehicleread: https://vehicleread.com
        </p>
        <p>
          Reading and accepting the Terms and Conditions by the User are prerequisites for using the Website.
        </p>
        <p>
          The official website of vehicleread - https://vehicleread.com concerns with all entities taking interest in services offered by the vehicleread through the website
        </p>
      </div>
      <div className="mb-8">
        <h2>§ 3 ELECTRONIC SERVICES OFFERED ON THE WEBSITE</h2>

        <p>3.1.vehicleread offers services through its official website that consist of:</p>
        <p> A. Accessing individual vehicle data and information</p>
        <p> B. Accessing VIN code and visual information of the vehicles</p>
        <p> C. Allowing Users to request removal of VIN information</p>
        <p> D. Giving Users the right to request updating any information related to specific Vehicles</p>
        3.2. No separate agreement is required for performing the services listed above by the Users or other involved parties
      </div>
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">§ 4 VEHICLE</h2>
        <p>
          vehicleread does not take responsibility for the accuracy of vehicle information displayed on the website, as vehicleread takes information from the official sources who are responsible for updating and checking the data, in particular, vehicleread does not take responsibility for:
        </p>
      </div>
      <div className="mb-8">
        <p>Inaccurate odometer reading of a specific Vehicle</p>
        <p> Technical condition of the Vehicle</p>
        <p> Current appearance of the Vehicle</p>
        <p> Ownership information of the Vehicle</p>
        <p> Lot price information of the Vehicle</p>
      </div>
      <div className="mb-8">
        <h2>§ 5 Terms and Conditions of Services</h2>
        <p className="text-lg">
          Services are accessible to any User interested and are offered free of charge (with the exception of VIN removal service). In order to use services, Users are required to meet specific technical requirements on the website of vehicleread:
          User must have an electronic device that allows:
         <p> Access to the internet</p> 
         <p> Browsing different web sources</p>
          <p>Communication through electronic mail</p>
        </p>
      </div>
    </div>
  );
};

export default Terms;
