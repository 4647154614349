export const handleGenYear = (min, max) => {
    let generatedData = [];
    for (let i = min; i <= max; i++) {
        generatedData.push(<option key={i} id={i} value={i}>{i}</option>)
    }
    return generatedData;
}


export const handleGenYearForProduct = (min, max) => {
    let generatedData = [];
    for (let i = min; i <= max; i++) {
        generatedData.push({year: i})
    }
    return generatedData;
}
