import React from 'react'

const Faq = () => {
    return (
        <div className='w-full flex flex-col gap-2 justify-center items-start container mx-auto p-8 '>
            <h1 className='font-bold text-xl'>FAQs on VIN Codes and Vehicle Records</h1>
            <div>
                <span>Is there a free VIN check site?</span>
                <p>  Yes, vehicleread.com is a website that lets you check VIN information for free regarding any pre-owned vehicle for sale online.
                    The website has a database that stores VIN data regarding cars that have past damage records, repair and maintenance reports, or have technical issues.
                    vehicleread.com is a free VIN check site with the biggest pool of used and damaged vehicles on the internet.
                </p>
            </div>
            <span className='font-bold text-[16px]'>What is the best free VIN check site?</span>
            <p> vehicleread.com is the best free VIN check site that does not hide any information regarding the vehicle. All important data is accessible for free and to any user without further requirements. You can check mileage, primary and secondary damages of the vehicle, lot price, images, etc.</p>
            <span className='font-bold text-[16px]'>How to check VIN number?</span>
            <p> VIN number is available on your vehicle’s TechPassport or usually within the car (often in the lower left corner of your car dashboard or in front of the steering wheel). You can also use free websites for checking VIN data online. If you remember the manufacturing date of your car, engine details, and mileage information, you can use these websites.</p>
            <span className='font-bold text-[16px]' >How can I find my VIN number online for free?</span>
            <p> You can find your VIN number online for free with several methods. First of all, you can refer to the manufacturer’s website and log in to your account (if you have such). You can get your VIN number online for free with manufacturers only in case your vehicle is registered with them. Alternatively, you can navigate to car dealership portals (if you have bought your vehicle from a dealership).</p>
            <p> The best way to find your VIN number online for free is to use vehicle report websites like vehicleread.com. Some cars have license plate numbers including VIN code which may be helpful for finding your VIN number. Also, you can check National Insurange Crime Bureau (NICB) - website for reported, stolen, and salvage vehicles, which provides free VIN check service.</p>

        </div>
    )
}

export default Faq
