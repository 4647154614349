import React from "react";
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { linkClick } from "../handelclicks/link-click";

const MainPagination = ({ currentPage, totalPages}) => {
    const pageNumbers = [];


    // Define the range of pages to display
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = startPage + maxPagesToShow - 1;

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="  flex justify-center items-center space-x-2 my-4">
            {/* First Page */}
            {currentPage > 1 && (
                <Link
                    to={`/vin-decoder?page=1`}
                    onClick={(e) => {
                        e.preventDefault();
                        linkClick(`/vin-decoder?page=1`);
                    }}
                    className="px-3 py-1 border rounded-full hover:bg-gray-200 flex items-center justify-center"
                    aria-label="First Page"
                >
                    <FaAngleDoubleLeft />
                </Link>
            )}

            {/* Previous Page */}
            {currentPage > 1 && (
                <Link
                    to={`/vin-decoder?page=${currentPage - 1}`}
                    onClick={(e) => {
                        e.preventDefault();
                        linkClick(`/vin-decoder?page=${currentPage - 1}`);
                    }}
                    className="px-3 py-1 border rounded-full hover:bg-gray-200 flex items-center justify-center"
                    aria-label="Previous Page"
                >
                    <FaAngleLeft />
                </Link>
            )}

            {/* Show ellipsis before the current range if not starting from 1 */}
            {startPage > 1 && (
                <>
                    <span className="px-3 py-1">...</span>
                </>
            )}
 
            {/* Page Numbers */}
            {pageNumbers.map((number) => (
                <Link
                    key={number}
                    to={`/vin-decoder?page=${number}`}
                    onClick={(e) => {
                        e.preventDefault();
                        linkClick(`/vin-decoder?page=${number}`);
                    }}
                    className={`px-3 py-1 border rounded-full ${currentPage === number ? "bg-blue-500 text-white" : "hover:bg-gray-200"
                        }`}
                >
                    {number}
                </Link>
            ))}

            {/* Show ellipsis after the current range if not ending at totalPages */}
            {endPage < totalPages && (
                <>
                    <span className="px-3 py-1">...</span>
                </>
            )}

            {/* Next Page */}
            {currentPage < totalPages && (
                <Link
                    to={`/vin-decoder?page=${currentPage + 1}`}
                    onClick={(e) => {
                        e.preventDefault();
                        linkClick(`/vin-decoder?page=${currentPage + 1}`);
                    }}
                    className="px-3 py-1 border rounded-full hover:bg-gray-200 flex items-center justify-center"
                    aria-label="Next Page"
                >
                    <FaAngleRight />
                </Link>
            )}

            {/* Last Page */}
            {currentPage < totalPages && (
                <Link
                    to={`/vin-decoder?page=${totalPages}`}
                    onClick={(e) => {
                        e.preventDefault();
                        linkClick(`/vin-decoder?page=${totalPages}`);
                    }}
                    className="px-3 py-1 border rounded-full hover:bg-gray-200 flex items-center justify-center"
                    aria-label="Last Page"
                >
                    <FaAngleDoubleRight />
                </Link>
            )}
        </div>
    );
};

export default MainPagination;


