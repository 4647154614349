import React from 'react'
import { useLocation } from 'react-router-dom'
import Toyota from './Toyota-Decoder';
import BmwDecoder from './Bmw-Decoder';

const VinDecoderTextBottom = () => {
  const { pathname } = useLocation();
  return (
    <div className=' p-2   m-2 w-full flex flex-col justify-center items-center bg-gradient-to-b '>
      {pathname === '/vin-decoder/toyota/all-models/all-years' ? Toyota.Toyota_bottom() : null}
      {pathname === '/vin-decoder/bmw/all-models/all-years' ? BmwDecoder.Bmw_Bottom() : null}
    </div>
  )
}

export default VinDecoderTextBottom
