import * as PAYPAL from '@paypal/react-paypal-js';
import React from 'react';
import * as FA from "react-icons/fa";
import * as Io from 'react-icons/io';
import PaymentForm from '../payment/PaymentForm';
import './Paymodal.css'; // Import a separate CSS file
const PaymentModal = ({ closeModal, carId }) => {
  const [clientToken, setClientToken] = React.useState(null);

  const [paypalOpen, setPaypalOpen] = React.useState(false);


  React.useEffect(() => {
    (async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/checkout/auc/token`, {
        method: "POST",
      });
      const { client_token } = await response.json();
      setClientToken(client_token);
    })();
  }, []);

  const handlePaypalOpen = () => {
    setPaypalOpen(true);
  }







  const initialOptions = {
    "client-id": process.env.REACT_APP_CLIENT_API_PAYPAL,
    "enable-funding": "",
    "disable-funding": "sepa,bancontact,giropay,sofort,ideal,mybank,p24,eps,blik,trustly,venmo,paylater",
    "data-sdk-integration-source": "integrationbuilder_ac",
    dataClientToken: clientToken,
    components: "hosted-fields,buttons",
  };
  return (
    <div className="payment-modal-container z-50">



      {(paypalOpen && clientToken) ? <PAYPAL.PayPalScriptProvider options={initialOptions}>
        <PaymentForm setPaypal={setPaypalOpen} />
      </PAYPAL.PayPalScriptProvider> : <div className="payment-modal">
        <h2 className="text-lg font-semibold mb-2">Payment Details</h2>
        <Io.IoIosClose className="close-icon" onClick={closeModal} />
        <div className="service-description bg-green-100 p-4 mb-4">
          <h3 className="text-lg font-semibold mb-2">Our Service Offer:</h3>

          <div className='grid grid-cols-2 gap-y-2 p-3'>
            <div className='flex items-center gap-2'>
              <FA.FaCheckCircle size="20" color="green" /> <span>Hide Vehicle from Google </span>
            </div>

            <div className='flex items-center gap-2'>
              <FA.FaCheckCircle size="20" color="green" /> <span>Hide Images from Google </span>
            </div>

            <div className='flex items-center gap-2'>
              <FA.FaCheckCircle size="20" color="green" /> <span>Hide Vehicle from vehicleread</span>
            </div>

            <div className='flex items-center gap-2'>
              <FA.FaCheckCircle size="20" color="green" /> <span>Hide Images from vehicleread</span>
            </div>

          </div>

          <p className='font-bold'>Total Amount: $49.99</p>

          <button
            onClick={handlePaypalOpen}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          >
            Pay Now
          </button>


        </div>
      </div>}




    </div>
  );
};

export default PaymentModal;