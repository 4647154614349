import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FcShipped } from 'react-icons/fc';

const DropdownMenu = ({ makeModelsData }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleToggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen); // Toggle the dropdown state
    };

    return (
        <li
            className="relative py-2 flex items-center gap-2 cursor-pointer"
            onClick={handleToggleDropdown} // Use onClick to toggle the dropdown
        >
            <FcShipped className="w-6 h-6" />
            Vehicle Info
            {isDropdownOpen && (
                <div className="absolute top-full left-0 mt-2 w-[300px] h-[300px] bg-white text-black shadow-lg rounded-lg z-50  overflow-y-auto">
                    <div className="p-4 flex flex-col gap-4">
                        <div>
                            <h3 className="font-bold mb-2">Makes</h3>
                            <ul>
                                {makeModelsData.map((make, index) => (
                                    <Link key={index} to={`/vin-decoder/${make.name?.toLowerCase()}/all-models/all-years?page=1`} className="block hover:text-gray-500">
                                        {make.name}
                                    </Link>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h3 className="font-bold mb-2">Models</h3>
                            <ul>
                                {makeModelsData.map((model, index) => (
                                    <Link key={index} to={`/vin-decoder/${model.name?.toLowerCase()}/${model.model?.toLowerCase()}/all-years?page=1`} className="block hover:text-gray-500">
                                        {model.name} {model.model}
                                    </Link>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <h3 className="font-bold mb-2">Years</h3>
                            <ul>
                                {makeModelsData.map((year, index) => (
                                    <Link key={index} to={`/vin-decoder/${year.name?.toLowerCase()}/${year.model?.toLowerCase()}/${year.year}?page=1`} className="block hover:text-gray-500">
                                        {year.name} {year.model} {year.year}
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </li>
    );
};

export default DropdownMenu;
