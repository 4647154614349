import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { linkClick } from '../handelclicks/link-click';
import {  handleGenYearForProduct } from '../../utils/gen-year';

const YearEnum = () => {

    const params = useParams();
    const { make, model } = params;

    const [showAll, setShowAll] = useState(false);

    const handleToggle = () => {
        setShowAll(!showAll);
    };
 
  
    return (
        <div className="container flex flex-col mx-auto p-4">
            <span className="text-xl font-bold text-center mb-6">Available Years for {make} {model}  </span>
            <ul className="grid grid-cols-1 gap-4">
                {handleGenYearForProduct(2013, 2025)?.slice(0, showAll ? handleGenYearForProduct(2013, 2025)?.length : 3).map((year, index) => (
                    <li key={index} className="bg-white shadow-lg rounded-lg p-4 transition transform hover:scale-105">
                        <Link to={`/vin-decoder/${make}/${model}/all-years?page=1`} onClick={() => linkClick(`/vin-decoder/${make}/${model}/${year?.year}?page=1`)}>
                            <span className="text-xl font-semibold mb-2">{year?.year}</span>
                        </Link>
                    </li>
                ))}
            </ul>
            {handleGenYearForProduct(2013, 2025)?.length > 3 && (
                <div className="flex justify-center mt-4">
                    <button
                        onClick={handleToggle}
                        className="text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out transform hover:scale-110"
                    >
                        {showAll ? 'Show Less ▲' : 'Show More ▼'}
                    </button>
                </div>
            )}
        </div>
    )
}

export default YearEnum;
