
export const generate_h1_tag = ( make, model, year) => {
    if (make !== "all-makes" && model === "all-models" && year === "all-years") {
        return `Free Vin Decoder ${make} `
    } else if (make !== "all-makes" && model !== "all-models" && year === "all-years") {
        return `Free Vin Decoder ${make} ${model} `
    }
    else if (make !== "all-makes" && model !== "all-models" && year !== "all-years") {
        return `Free Vin Decoder ${make} ${model} ${year} `
    }
    else{
        return ""
    }
} 