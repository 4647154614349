import publicRequest  from "./requestMethods";

const requestUtils = {
  _getNewProduct: async () => {
    try {
      const newProductdata = await publicRequest.get("/random/products");
      return newProductdata.data;
    } catch (err) {}
  },
  _getSuggestProduct: async () => {
    try {
      const SuggestProductdata = await publicRequest.get("/suggest/random");
      return SuggestProductdata.data;
    } catch (err) {}
  },

  _getProductByVin: async (vin) => {
    try {
      const productByVin = await publicRequest.get("/get/auc/" + vin);
      return productByVin.data;
    } catch (err) {
      return err;
    }
  },
  _getProductByLot: async (lot) => {
    try {
      const productByVin = await publicRequest.get("/product/auc/" + lot);
      return productByVin.data;
    } catch (err) {
      return err;
    }
  },
  _getProductByQuery: async (query,vehiclePageCount) => {
    try {
      const productByQuery = await publicRequest.get(
        "/vehicle?searchVehicle=" +  query + "&page="+ vehiclePageCount
      );
      return productByQuery.data;
    } catch (err) {
      return err;
    }
  },
  _getProductsByUpdate: async (make,model,year,vehiclePageCount) => {
    try {
      const productByQuery = await publicRequest.get(`/cars/auc?make=${make ? make : "all-makes"}&model=${model ? model : "all-models"}&year=${year ? year : "all-years"}&page=${vehiclePageCount}` );
      return productByQuery.data;
    } catch (err) {
      return err;
    }
  },
};

export default requestUtils;
