import { Outlet } from 'react-router-dom'
import Footer from '../components/footer/Footer'
import Topbar from '../components/topbar/Topbar'


const MainLayout = () => {
  return (
    <div className='overflow-hidden'>
      <Topbar />
      <Outlet />
      <Footer />
    </div>
  )
}

export default MainLayout
